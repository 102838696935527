import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { fechaATexto } from '../../lib/helpers/helpers'
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { es } from 'date-fns/locale'
import { opcionesfecha } from '../../lib/helpers/opcionesfecha'
import datos from '../../lib/global/data'

class ListsNew extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            listas: [],
            loadingEmails: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            filtro_listas_navegacion: '',
            filtro_listas_pedidos: '',
            filtro_listas: '',
            nuevoregistro: {},
            categorias: [],
            etiquetas: [],
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            consultandoCampos: false,
            usuarios: [],
            creandoLista: false,
            creado: false,
            regionseleccionada: false,
            comunasseleccionadas: false,
            campos_personalizados: [],
            loadingCampos: false,
            campos_seleccionados: []

        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeSelectSubTipo = this.handleChangeSelectSubTipo.bind(this)
        this.handleChangeSelectRecurso = this.handleChangeSelectRecurso.bind(this)
        this.handleChangeSelectRegion = this.handleChangeSelectRegion.bind(this)
        this.handleChangeSelectComuna = this.handleChangeSelectComuna.bind(this)
        this.handleChangeSelectValor = this.handleChangeSelectValor.bind(this)
        this.handleChangeRegistro = this.handleChangeRegistro.bind(this)
        this.handleChangeSelectTipo = this.handleChangeSelectTipo.bind(this)
        this.handleChangeCampoPersonalizado = this.handleChangeCampoPersonalizado.bind(this)
    }

    
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    cambiarRangoFecha(item){
        this.setState({ selectionRange: item.selection })
    }

    handleChangeCampoPersonalizado(e){
        const { nuevoregistro } = this.state
        nuevoregistro.marcas = e
        if(e.length < 1) delete nuevoregistro.marcas
        return this.setState({ nuevoregistro })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user } = this.state
        this.getCategorias(user.web)
        this.getEtiquetas(user.web)
        this.getFields(user.web)
        document.addEventListener('scroll', this.trackScrolling);

    }

    getFields(web){
        this.setState({ loadingCampos: true })
        return fetch(`${data.urlapi}/api/settings/custom-fields/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingCampos: false, campos_personalizados: res })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingCampos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }
    
    guardarCrear(){
        const { usuario, user } = this.state

        if(!usuario.nombres||!usuario.apellidopaterno||!usuario.apellidomaterno||!usuario.rut||!usuario.fechanacimiento||!usuario.tipo||!usuario.cargo){
            return alert("Todos los campos son requeridos")
        }
        usuario.propietario = user.propietario
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_editarCrearUsuario',{
            method: 'POST',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = usuario._id ? 'Usuario modificado exitosamente' : 'Usuario creado exitosamente'
            this.setState({ guardandocambios: false, textguardado: texto })
            this.getListas(user)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getEtiquetas(web){

        this.setState({ loadingEtiquetas: true })
        return fetch(`${data.urlapi}/api/etiquetas/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEtiquetas: false, etiquetas: res })
        })
        .catch(error => {
            this.setState({ loadingEtiquetas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getCategorias(web){

        this.setState({ loadingCategorias: true })
        return fetch(`${data.urlapi}/api/categorias/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingCategorias: false, categorias: res })
        })
        .catch(error => {
            this.setState({ loadingCategorias: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchListas(web){

        this.setState({ loadingEmails: true })
        return fetch(`${data.urlapi}/api/listas/list-complete?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEmails: false, listas: res })
        })
        .catch(error => {
            this.setState({ loadingEmails: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    getListas(user){
        return this.fetchListas(user.web)
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }


    handleChangeRegistro(e){
        const { name, value } = e.target
        const { nuevoregistro } = this.state
        nuevoregistro[name] = value
        return this.setState({ nuevoregistro })
    }
    handleChangeSelectValor(e){
        const { nuevoregistro } = this.state
        nuevoregistro.subtipo = e.value
        return this.setState({ nuevoregistro })
    }

    handleChangeSelectTipo(e){
        const { nuevoregistro } = this.state
        nuevoregistro.valor = e
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    handleChangeSelectRecurso(e){
        const { nuevoregistro } = this.state
        nuevoregistro.recurso = e.value
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    handleChangeSelectRegion(e){
        const { nuevoregistro } = this.state
        nuevoregistro.region = e.value
        if(e.length < 1) delete nuevoregistro.region
        return this.setState({ nuevoregistro, usuarios: [], regionseleccionada: e.value })
    }

    handleChangeSelectComuna(e){
        const { nuevoregistro } = this.state
        nuevoregistro.comuna = e
        if(e.length < 1) delete nuevoregistro.comuna
        return this.setState({ nuevoregistro, comunasseleccionadas: e, usuarios: [] })
    }

    handleChangeSelectSubTipo(e){
        const { nuevoregistro } = this.state
        nuevoregistro.subtipo = e.value
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    ifCamposPersonalizados(campos){

        if(campos.length < 1) return false

        return <div>

            {
                campos.map(field => {

                    let valores = []

                    field.valores.map(valor => {
                        valores.push({ value: valor, label: valor })
                    })

                    return <div>
                        <label className="form-control-label">{field.titulo}</label>
                        <Select 
                            style={{ marginBottom: 10 }}
                            name={field.slug}
                            onChange={this.handleChangeCampoPersonalizado}
                            options={valores}
                            isMulti={true}
                            placeholder="Seleccionar"
                        />
                    </div>

                })
            }
        </div>

    }

    formularioLista(){

        const { nuevoregistro, selectionRange, campos_personalizados, regionseleccionada, creado, creandoLista, usuarios, consultandoCampos } = this.state

        if(creado) return <Row className="justify-content-md-center mt-7">
        <Col md={6} className="text-center ">
        <img className="iconsmall mb-3" src="check.gif" />
        <h5>Creado exitosamente</h5>
        <p className="nomargin">Tu lista se creó exitosamente con {usuarios.length} usuarios...</p>
        <Link to="/lists">Atrás</Link>
        </Col>
        </Row> 

        let rangoactual = ''

        if(selectionRange.startDate){
            const stringdesde = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += `desde ${stringdesde}`
        }

        if(selectionRange.endDate){
            const stringhasta = selectionRange.endDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual += ` hasta ${stringhasta}`
        }

        if(selectionRange.startDate === selectionRange.endDate){
            const stringunicafecha = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
            rangoactual = ''
            rangoactual = stringunicafecha
        }

        const options = [
            //{ value: 'navegacion', label: 'Navegación' },
            { value: 'pedidos', label: 'Pedidos' },
            { value: 'usuarios', label: 'Usuarios' }
        ]

        const optionstipo = [
            { value:'', label: 'Ninguno'},
            { value: 'categoria', label: 'Categoría' },
            { value: 'etiqueta', label: 'Etiqueta' },
            { value: 'producto', label: 'Producto' }
        ]

        if(campos_personalizados.length > 0){

            campos_personalizados.map(campo => {
                optionstipo.push({ value: campo.slug, label: campo.titulo })
            })

        }

        let regiones = []

        datos.regionesycomunas.map(region => {
            regiones.push({ value: region.nombre, label: region.nombre })
        })

        let comunas = []

        if(regionseleccionada){
            const buscar_comunas = datos.regionesycomunas.filter(re => re.nombre === regionseleccionada)
            if(buscar_comunas.length > 0){
                buscar_comunas[0].comunas.map(com => {
                    comunas.push({ value: com, label: com })
                })
            }
        }

        return <div>

        <Card>
            <Card.Body>
                
                <Row>
            <Col md={3}>
            <label className="form-control-label">Título</label>
            <input name="titulo" className="form-control mb-4" onChange={this.handleChangeRegistro} />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Datos basados en</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectSubTipo}
                options={options}
                defaultValue={''}
                placeholder="Seleccionar fuente"
              />
            </Col>

            {
                nuevoregistro.subtipo === 'pedidos' ? <Col md={6}>
                <label className="form-control-label">Tipo</label>
                <Select 
                    style={{ marginBottom: 10 }}
                    onChange={this.handleChangeSelectRecurso}
                    options={optionstipo}
                    defaultValue={''}
                    placeholder="Seleccionar tipo"
                  />
                </Col> : false
            }
            

            { nuevoregistro.subtipo === 'pedidos' || nuevoregistro.subtipo === 'usuarios' ? <Col md={3}>
            <label className="form-control-label">Región (opcional)</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectRegion}
                options={regiones}
                defaultValue={''}
                placeholder="Selecciona una región"
              />
            </Col> : false }

            { nuevoregistro.subtipo === 'pedidos' || nuevoregistro.subtipo === 'usuarios' ? <Col md={9}>
            <label className="form-control-label">Comuna (opcional)</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectComuna}
                options={comunas}
                defaultValue={''}
                isMulti={true}
                placeholder="Selecciona una región"
              />
            </Col> : false }

            {this.ifTipo(nuevoregistro.recurso)}
            <Col xs={12} className="mb-3"><hr />
            { creandoLista ? <Spinner animation="border" /> : <button className="btn btn-outline-success" onClick={()=>this.crearLista()}>CREAR LISTA VACÍA</button> }</Col>

            <Col xs={12}>
            { consultandoCampos ? <Spinner animation="border" /> : <button className="btn btn-outline-primary" onClick={()=>this.consultarDatos()}>CONSULTAR</button> }
            { usuarios.length > 0 ? <div>
                <hr className="hr" />
                <h4><i className="far fa-user"></i> {usuarios.length} Usuarios encontrados</h4>
                { creandoLista ? <Spinner animation="border" /> : <button className="btn btn-success" onClick={()=>this.crearLista()}>CREAR LISTA</button> }
            </div> : false }
            </Col>

            {
                nuevoregistro.subtipo === 'pedidos' ? <Col md={12}>
                <h5>Rango seleccionado</h5>
            <label className="form-control-label d-block"><b>{nuevoregistro.subtipo}</b> {rangoactual}</label>
            
            { usuarios.length > 0 ? <div>

                { creandoLista ? <Spinner animation="border" /> : <button className="btn btn-success" onClick={()=>this.crearLista()}>CREAR LISTA</button> }

            </div> : false }
            <hr />
<DateRangePicker
                    locale={es}
                showSelectionPreview={true}
                editableDateInputs={true}
                onChange={item => this.cambiarRangoFecha(item)}
                moveRangeOnFirstSelection={false}
                ranges={[selectionRange]}
                direction="horizontal"
                scroll={{ enabled: true }}
                months={2}
              />
</Col> : false
            }
            
                </Row>
            </Card.Body>
        </Card>

        </div>
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }

        try {
            
        } catch (error) {
            
        }
        return res;
    }

    subirUsuariosALista(idlista,usuarios){

        return fetch(`${data.urlapi}/api/listas/adduserstolist`,{
            method: 'POST',
            body: JSON.stringify({ idlista, usuarios }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(async res =>  res)
        .catch(error => {
            toast.error("Error al agregar usuarios a la lista, intente nuevamente", this.state.toaststyle)
        })
    }

    async crearLista(){
        const { nuevoregistro, usuarios, user } = this.state

        nuevoregistro.web = user.web
        // if(!nuevoregistro.recurso || !nuevoregistro.subtipo || !nuevoregistro.valor || !nuevoregistro.titulo) return toast.error('Completa todos los campos', this.state.toaststyle)
        nuevoregistro.subtipo = nuevoregistro.subtipo ? nuevoregistro.subtipo : 'usuario'

        let arraydividido = []
        // if(usuarios.length < 1) return toast.error(`No se encontraron usuarios en el filtro`, this.state.toaststyle)
        arraydividido = await this.sliceIntoChunks(usuarios, 300)

        this.setState({ creandoLista: true })
        return fetch(`${data.urlapi}/api/listas/new/custom`,{
            method: 'POST',
            body: JSON.stringify({ lista: nuevoregistro, usuarios }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(async res => {

            const recorrer = await Promise.all( await arraydividido.map(async users => {
                const procesar = await this.subirUsuariosALista(res._id, users)
            }) )

            this.setState({ creandoLista: false, creado: true })
        })
        .catch(error => {
            this.setState({ creandoLista: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    consultarDatos(){
        const { nuevoregistro, selectionRange, consultandoCampos, user } = this.state

        if(!nuevoregistro.titulo || !nuevoregistro.subtipo) return toast.error('Completa todos los campos', this.state.toaststyle)
        nuevoregistro.start = this.formatDate(selectionRange.startDate)
        nuevoregistro.end = this.formatDate(selectionRange.endDate)
        nuevoregistro.web = user.web

        if(nuevoregistro.subtipo === 'pedidos'){
            if(!nuevoregistro.recurso || !nuevoregistro.subtipo || !nuevoregistro.valor || !nuevoregistro.titulo) return toast.error('Completa todos los campos', this.state.toaststyle)
        } else if (nuevoregistro.subtipo === 'usuarios'){
            if(!nuevoregistro.subtipo || !nuevoregistro.region || !nuevoregistro.titulo) return toast.error('Completa todos los campos', this.state.toaststyle)
        }

        console.log(nuevoregistro)

        this.setState({ consultandoCampos: true })
        return fetch(`${data.urlapi}/api/listas/get/users`,{
            method: 'POST',
            body: JSON.stringify(nuevoregistro),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            if(res.length < 1) toast.warn('Sin resultados', this.state.toaststyle)
            console.log(res)
            this.setState({ consultandoCampos: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ consultandoCampos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    ifTipo(tipo){
        const { categorias, etiquetas, campos_personalizados } = this.state

        let options_categorias = []
        let options_etiquetas = []

        categorias.map(cat => {
            options_categorias.push({ value: cat.slug, label: cat.name })
        })

        etiquetas.map(cat => {
            options_etiquetas.push({ value: cat.slug, label: cat.name })
        })

        if(tipo==='categoria'){
            return <Col md={12}>
            <label className="form-control-label">Valor</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectTipo}
                options={options_categorias}
                defaultValue={''}
                isMulti={true}
                placeholder="Seleccionar categoria"
              />
            </Col>

        } else if(tipo==='etiqueta'){

            return <Col md={12}>
            <label className="form-control-label">Valor</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectTipo}
                options={options_etiquetas}
                defaultValue={''}
                isMulti={true}
                placeholder="Seleccionar etiqueta"
              />
            </Col>

        } else if(tipo==='producto'){

            return <Col md={12}>
            <label className="form-control-label">SKU</label>
            <input className="form-control" onChange={this.handleChangeRegistro} name="valor" placeholder="SKU del producto" />
            </Col>

        } else if(tipo === 'marcas') {

            let valores = []

            const seleccionado = campos_personalizados.findIndex(field => field.slug === tipo)

            if(seleccionado > -1){
                campos_personalizados[seleccionado].valores.map(valor => {
                    valores.push({ value: valor, label: valor })
                })
            }

            return <Col md={12}>
            <label className="form-control-label">Valor</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectTipo}
                options={valores}
                defaultValue={''}
                isMulti={true}
                placeholder="Seleccionar etiqueta"
              />
            </Col>


        }

        return false

    }

    mostrarEmails(){
        const { loadingEmails, listas, cargos, filtro_listas, filtro_listas_navegacion, filtro_listas_pedidos, areas, filtrousuario, textguardado, tipousuario, showcreacion } = this.state

        if(loadingEmails){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const listas_navegacion = listas.filter(list => list.subtipo === 'navegacion')
        const listas_pedidos = listas.filter(list => list.subtipo === 'pedidos')

        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{listas.length} Listas encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>
            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtrousuario" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            <Col xs={12}>
            <Tabs defaultActiveKey="todos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="todos" title="Todas las listas">
            {this.mostrarDatosListas(listas, filtro_listas, 'filtro_listas')}
            </Tab>
            <Tab eventKey="navegacion" title="Basadas en navegación">
            {this.mostrarDatosListas(listas_navegacion, filtro_listas_navegacion, 'filtro_listas_navegacion')}
            </Tab>
            <Tab eventKey="pedidos" title="Basadas en pedidos">
            {this.mostrarDatosListas(listas_pedidos, filtro_listas_pedidos, 'filtro_listas_pedidos')}
            </Tab>
            </Tabs>
            </Col>

          
          </Row>          

          

        </Card.Body>
      </Card>
    }

    mostrarDatosListas(datos, filtro, input){

        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} listas</h4>
<div className="form-group">
<label className="form-control-label">Filtrar resultados</label>
<input className="form-control" name={input} onChange={this.handleChange} />
</div>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}


<Table responsive hover>
  <thead>
    <tr>
    <th>Título</th>
    <th>Usuarios</th>
    <th>Tipo</th>
    <th>Recurso</th>
    <th>Subtipo</th>
    <th>Creado</th>
    </tr>
  </thead>
  <tbody>
    { listasFiltradas.map((lista,i)=>{
        return <tr key={`user${i}`}>
            <th>{lista.titulo}
            <Link className="d-block" to={`/lista-${lista._id}`} >Ver detalles</Link>
            </th>
            <th>{lista.usuarios.length}</th>
            <th>{lista.tipo}</th>
            <th>{lista.recurso}</th>
            <th><h6><span class="badge bg-secondary text-light">{lista.subtipo.toUpperCase() }</span></h6></th>
            <th>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{fechaATexto(lista.createdAt,'es-ES')}</Tooltip>}>
            <Moment locale="ES" fromNow>{lista.createdAt}</Moment>
            </OverlayTrigger>
                </th>
        </tr>
    }) }
  </tbody>
</Table>
        </div>
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Crear lista </h1>
      </div>

      {this.formularioLista()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(ListsNew);