import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'
import { Row, Col, Card,  Container, Form, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'

class Permisos extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtromatrices: '',
            matriz: {},
            showcreacion: false,
            textguardado: '',
            loadingmatrices: true,
            roles: [],
            loadingconfiguracion: true,
            modulo: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeMatriz = this.handleChangeMatriz.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeMatriz(e){
        const { name, value } = e.target
        const { matriz } = this.state
        matriz[name] = value
        console.log(matriz)
        return this.setState({ matriz: matriz })
    }

    componentDidMount(){
        const { user } = this.state
        this.getMatriz('matricespermisos')
        this.getMatrices(user.propietario)
    }

    getMatriz(tipo){

        this.setState({ loadingconfiguracion: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMatriz?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            
            this.setState({ loadingconfiguracion: false, configuracion: res })
        })
        .catch(error => {
            this.setState({ loadingconfiguracion: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getMatrices(propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMatrices?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }
    
    guardarCrear(){
        const { matriz, user } = this.state

        if(!matriz.nombre||!matriz.esquema){
            return alert("Todos los campos son requeridos")
        } else if(matriz.esquema.length < 1){
            return alert("No agregaste ningún módulo a la matriz")
        }
        matriz.propietario = user.propietario
        matriz.status = "1"
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_crearEditarMatriz',{
            method: 'POST',
            body: JSON.stringify(matriz),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = matriz._id ? 'Matriz modificada exitosamente' : 'Matriz creada exitosamente'
            this.setState({ guardandocambios: false, textguardado: texto, showcreacion: false, matriz: {} })
            this.getMatrices(user.propietario)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
                break;
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
                break;
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }

    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    removerItem(slugesquema){
        const { matriz } = this.state
        const posesquema = matriz.esquema.findIndex(e => e.slug === slugesquema)
        if(matriz.esquema[posesquema]){
            console.log(matriz.esquema[posesquema])
            matriz.esquema.splice(posesquema,1)
            return this.setState({ matriz: matriz })
        }
    }

    activarItem(slugesquema, slugpermisos){
        const { matriz } = this.state

        const posesquema = matriz.esquema.findIndex(e => e.slug === slugesquema)

        if(matriz.esquema[posesquema]){

            const pospermiso = matriz.esquema[posesquema].permisos.findIndex(per => per.nombre === slugpermisos)

            if(matriz.esquema[posesquema].permisos[pospermiso]){

                matriz.esquema[posesquema].permisos[pospermiso].status = matriz.esquema[posesquema].permisos[pospermiso].status === true ? false : true
                return this.setState({ matriz: matriz })
            }

        }

    }

    formularioNuevoUser(){
        const { showcreacion, matriz, guardandocambios, modulo, matrices, configuracion } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { matriz._id ? <h3>Editar matriz {matriz.nombre ? matriz.nombre : 'usuario'} </h3> : <h3>Crear matriz</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={matriz.nombre ? matriz.nombre : ''} onChange={this.handleChangeMatriz} />
</Col>


<Col md={3} >
    <label className="form-control-label">Módulos</label>
    <select name="modulo" className="form-control" value={modulo ? modulo : ''} onChange={this.handleChange} >
        <option value="">Seleccione</option>
        {
            configuracion.esquema ? configuracion.esquema.map(esq => {
                return <option value={esq.slug}> {esq.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={4}>
<label className="form-control-label">Click para agregar el módulo </label>
    <Button style={{ background: 'white' }} onClick={()=>this.anadirModulo()} variant="outline-white">AGREGAR</Button>
</Col>

<Col xs={12} style={{ paddingTop: 15 }}>

{
    matriz.esquema ? matriz.esquema.map((ma,i) => {
        return <div>
            <h4 className="mb-0">{i+1} Módulo {ma.nombre}</h4>
            <p >Activa los siguientes permisos haciendo click sobre ellos.</p>
            {
                ma.permisos.length > 0 ? ma.permisos.map(item => {
                    return <button className={item.status===true ? "botonactivo" : "botoninactivo"} onClick={()=>this.activarItem(ma.slug, item.nombre)} > {item.nombre} </button>
                }) : false
            }
            <button className="btn btn-sm btn-danger" onClick={()=>this.removerItem(ma.slug)} >REMOVER ESTE MÓDULO</button>
            { i+1 < matriz.esquema.length ? <hr /> : false }
        </div>
    }) : false
}

</Col>

<Col xs={12}>
    <hr />
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ matriz._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0" onClick={()=>this.setState({ matriz: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    anadirModulo(){
        const { modulo, configuracion, matriz } = this.state

        if(!modulo) return alert("Selecciona un módulo primero antes de agregar")

        if(configuracion){
            if(configuracion.esquema){
                const findpos = configuracion.esquema.findIndex(m => m.slug === modulo )
                if(configuracion.esquema[findpos]){
                    if(!matriz.esquema) matriz.esquema = []
                    const siexisteya = matriz.esquema.filter(p => p.slug === modulo)
                    if(siexisteya.length < 1) matriz.esquema.push(configuracion.esquema[findpos])
                    console.log(matriz)
                    return this.setState({ matriz: matriz })
                }
            }
        }
    }

    mostrarMatrices(){
        const { loadingmatrices, filtromatrices, textguardado, matrices, showcreacion } = this.state

        if(loadingmatrices){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        if(matrices.length < 1) return <Card style={{ width: '100%' }}>
        <Card.Body>
        <Card.Title style={{ float: 'left' }}><h3>No se encontraron resultados</h3></Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
          { textguardado ? <h2> {textguardado} </h2> : false }
          {this.formularioNuevoUser()}

        </Card.Body>
        </Card>

        const lowercasedFilter = filtromatrices.toLowerCase();
        const matricesFiltrados = matrices.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{matrices.length} matrices encontrados</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtromatrices" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            {filtromatrices !== '' ? <Col xs={12}><label className="form-control-label">{matricesFiltrados.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Estado</th>
      <th>Módulos</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { matricesFiltrados.map((rol,i)=>{
        return <tr key={`user${i}`}>
            <th>{rol.nombre}<br/><small className="text-primary" onClick={()=>this.setState({ showcreacion: true, matriz: rol, textguardado: '' })}>Editar</small></th>
            <th>{this.statusUser(rol.status)}</th>
            <th>{rol.esquema ? rol.esquema.map(e => `${e.nombre} `) : false } </th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Matrices de permisos</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

      {this.mostrarMatrices()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Permisos);