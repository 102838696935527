import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { fechaATexto } from '../../lib/helpers/helpers'
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { es } from 'date-fns/locale'
import { opcionesfecha } from '../../lib/helpers/opcionesfecha'
import datos from '../../lib/global/data'
import Badge from 'react-bootstrap/Badge'

class CustomFields extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            campos_personalizados: [],
            loadingCampos: false,
            filtrocampos: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            nuevoregistro: {
                valores: []
            },
            creado: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChangeSelectSubTipo = this.handleChangeSelectSubTipo.bind(this)
        this.handleChangeSelectRecurso = this.handleChangeSelectRecurso.bind(this)
        this.handleChangeSelectRegion = this.handleChangeSelectRegion.bind(this)
        this.handleChangeSelectComuna = this.handleChangeSelectComuna.bind(this)
        this.handleChangeSelectValor = this.handleChangeSelectValor.bind(this)
        this.handleChangeRegistro = this.handleChangeRegistro.bind(this)
        this.handleChangeSelectTipo = this.handleChangeSelectTipo.bind(this)
    }

    handleInputChange (event) {
        const { areas, cargos} = this.state
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                'area',
                'cargo'
            ]

            const convertirtexto = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                //'area',
                //'cargo'
            ]
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(row.rut) hojas[0][i].rut = row.rut.replace(/[\W_]+/g, "")

                convertirtexto.map(campo => {
                    if(row[campo]) hojas[0][i][campo] = row[campo].toString()
                })

                if(row.area){
                    const iarea = areas.findIndex(ar => ar.nombre === row.area)
                    if(areas[iarea]){
                        hojas[0][i].area = areas[iarea]._id['$oid']
                    } else {
                        columnasincompletas.push(`El área ${row.area} no existe, columna ${i+1}`)
                    }
                }

                if(row.cargo){
                    const icargo = cargos.findIndex(ar => ar.nombre === row.cargo)
                    if(cargos[icargo]){
                        hojas[0][i].cargo = cargos[icargo]._id['$oid']
                    } else {
                        columnasincompletas.push(`El cargo ${row.cargo} no existe, columna ${i+1}`)
                    }
                }

                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas[0],
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    cambiarRangoFecha(item){
        this.setState({ selectionRange: item.selection })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user } = this.state
        this.getFields(user.web)
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    getFields(web){
        this.setState({ loadingCampos: true })
        return fetch(`${data.urlapi}/api/settings/custom-fields/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingCampos: false, campos_personalizados: res })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingCampos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }
    
    guardarCrear(){
        const { nuevoregistro, user } = this.state

        if(!nuevoregistro.titulo||!nuevoregistro.slug||!nuevoregistro.tipo){
            return toast.error("Todos los campos son requeridos", this.state.toaststyle)
        } else if(nuevoregistro.valores.length < 1){
            return toast.error("Debes introducir al menos un valor para este campo", this.state.toaststyle)
        }
        nuevoregistro.creador = {
            email: user.email,
            nombres: `${user.nombres} ${user.apellido_paterno}`
        }
        nuevoregistro.web = user.web

        this.setState({ guardandocambios: true })
        return fetch(`${data.urlapi}/api/settings/new-custom-field`,{
            method: 'POST',
            body: JSON.stringify(nuevoregistro),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = nuevoregistro._id ? 'Datos modificados exitosamente' : 'Datos creados exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.getFields(user.web)
            this.setState({ guardandocambios: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getEtiquetas(web){

        this.setState({ loadingEtiquetas: true })
        return fetch(`${data.urlapi}/api/etiquetas/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingEtiquetas: false, etiquetas: res })
        })
        .catch(error => {
            this.setState({ loadingEtiquetas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getCategorias(web){

        this.setState({ loadingCategorias: true })
        return fetch(`${data.urlapi}/api/categorias/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingCategorias: false, categorias: res })
        })
        .catch(error => {
            this.setState({ loadingCategorias: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchListas(web){

        this.setState({ loadingCampos: true })
        return fetch(`${data.urlapi}/api/listas/list-complete?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingCampos: false, listas: res })
        })
        .catch(error => {
            this.setState({ loadingCampos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    getListas(user){
        return this.fetchListas(user.web)
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }


    handleChangeRegistro(e){
        const { name, value } = e.target
        const { nuevoregistro } = this.state
        nuevoregistro[name] = value
        if(name==='titulo'){
            let string = value.replace( /[\W_]+/g, '-')
            nuevoregistro.slug = string.toLowerCase()
        } else if(name==='valores'){

            let arreglo = []
            const array = value.split(',')
            array.map(val => {
                arreglo.push( val.trim() )
            })
            nuevoregistro.valores = arreglo

        }
        return this.setState({ nuevoregistro })
    }
    handleChangeSelectValor(e){
        const { nuevoregistro } = this.state
        nuevoregistro.subtipo = e.value
        return this.setState({ nuevoregistro })
    }

    handleChangeSelectTipo(e){
        const { nuevoregistro } = this.state
        nuevoregistro.valor = e
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    handleChangeSelectRecurso(e){
        const { nuevoregistro } = this.state
        nuevoregistro.recurso = e.value
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    handleChangeSelectRegion(e){
        const { nuevoregistro } = this.state
        nuevoregistro.region = e.value
        if(e.length < 1) delete nuevoregistro.region
        return this.setState({ nuevoregistro, usuarios: [], regionseleccionada: e.value })
    }

    handleChangeSelectComuna(e){
        const { nuevoregistro } = this.state
        nuevoregistro.comuna = e
        if(e.length < 1) delete nuevoregistro.comuna
        return this.setState({ nuevoregistro, comunasseleccionadas: e, usuarios: [] })
    }

    handleChangeSelectSubTipo(e){
        const { nuevoregistro } = this.state
        nuevoregistro.tipo = e.value
        return this.setState({ nuevoregistro, usuarios: [] })
    }

    formularioLista(){

        const { nuevoregistro, showcreacion } = this.state

        if(!showcreacion) return false

        const options = [
            { value: 'producto', label: 'Producto' },
            { value: 'pedido', label: 'Pedido' }
        ]


        return <div>

<Card className="card-blue">
<Card.Body className="text-white ">
                
                <Row>

            <Col md={12}>
                <h4>Crear nuevo campo</h4>
            </Col>

            <Col md={3}>
            <label className="form-control-label">Título</label>
            <input name="titulo" value={nuevoregistro.titulo} className="form-control mb-4" onChange={this.handleChangeRegistro} />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Slug</label>
            <input name="slug" value={nuevoregistro.slug} className="form-control mb-4" onChange={this.handleChangeRegistro} />
            </Col>

            <Col md={3} >
            <label className="form-control-label">Atributo pertenece a</label>
            <Select 
                className="text-dark"
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectSubTipo}
                options={options}
                defaultValue={''}
                placeholder="Seleccionar fuente"
              />
            </Col>

            <Col md={3} >
            <label className="form-control-label d-block">Click para guardar</label>
            <button className="btn btn-light" onClick={()=>this.guardarCrear()} >GUARDAR</button>
            </Col>

            <Col md={12} style={{ marginBottom: 5 }}>
            <label className="form-control-label">Pega aquí tus valores separados por coma (,)</label>
            <textarea name="valores" className="form-control" onChange={this.handleChangeRegistro}></textarea>
            </Col>

            <Col xs={12}><hr /></Col>

            <Col md={12}>
            <label className="form-control-label">Valores</label>
            
            { nuevoregistro.valores.length > 0 ? <div>
                {nuevoregistro.valores.map(valor => {
                    return <Badge style={{ marginRight: 4 }} variant="dark">{valor}</Badge>
                })}
            </div> : false }
            </Col>
            

            <Col xs={12}><hr /></Col>

            
                </Row>
            </Card.Body>
        </Card>

        </div>
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    crearLista(){
        const { nuevoregistro, usuarios, user } = this.state

        nuevoregistro.web = user.web
        if(!nuevoregistro.recurso || !nuevoregistro.subtipo || !nuevoregistro.valor || !nuevoregistro.titulo) return toast.error('Completa todos los campos', this.state.toaststyle)

        this.setState({ creandoLista: true })
        return fetch(`${data.urlapi}/api/listas/new/custom`,{
            method: 'POST',
            body: JSON.stringify({ lista: nuevoregistro, usuarios }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ creandoLista: false, creado: true })
        })
        .catch(error => {
            this.setState({ creandoLista: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    consultarDatos(){
        const { nuevoregistro, selectionRange, consultandoCampos, user } = this.state

        nuevoregistro.start = this.formatDate(selectionRange.startDate)
        nuevoregistro.end = this.formatDate(selectionRange.endDate)
        nuevoregistro.web = user.web

        if(!nuevoregistro.recurso || !nuevoregistro.subtipo || !nuevoregistro.valor || !nuevoregistro.titulo) return toast.error('Completa todos los campos', this.state.toaststyle)

        this.setState({ consultandoCampos: true })
        return fetch(`${data.urlapi}/api/listas/get/users`,{
            method: 'POST',
            body: JSON.stringify(nuevoregistro),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            
            if(res.length < 1) toast.warn('Sin resultados', this.state.toaststyle)
            
            this.setState({ consultandoCampos: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ consultandoCampos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    ifTipo(tipo){
        const { categorias, etiquetas } = this.state

        let options_categorias = []
        let options_etiquetas = []

        categorias.map(cat => {
            options_categorias.push({ value: cat.slug, label: cat.name })
        })

        etiquetas.map(cat => {
            options_etiquetas.push({ value: cat.slug, label: cat.name })
        })

        if(tipo==='categoria'){
            return <Col md={12}>
            <label className="form-control-label">Tipo</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectTipo}
                options={options_categorias}
                defaultValue={''}
                isMulti={true}
                placeholder="Seleccionar categoria"
              />
            </Col>

        } else if(tipo==='etiqueta'){

            return <Col md={12}>
            <label className="form-control-label">Tipo</label>
            <Select 
                style={{ marginBottom: 10 }}
                onChange={this.handleChangeSelectTipo}
                options={options_etiquetas}
                defaultValue={''}
                isMulti={true}
                placeholder="Seleccionar etiqueta"
              />
            </Col>

        } else if(tipo==='producto'){

            return <Col md={12}>
            <label className="form-control-label">SKU</label>
            <input className="form-control" onChange={this.handleChangeRegistro} name="valor" placeholder="SKU del producto" />
            </Col>

        }
        return false

    }

    mostrarCampos(){
        const { loadingCampos, campos_personalizados, filtrocampos, showcreacion } = this.state

        if(loadingCampos){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{campos_personalizados.length} Listas encontradas</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR NUEVO CAMPO</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        {this.formularioLista()}
          <Row>
              
          </Row>          

          {this.mostrarDatosCampos(campos_personalizados,filtrocampos,'filtrocampos')}

        </Card.Body>
      </Card>
    }

    mostrarDatosCampos(datos, filtro, input){

        const lowercasedFilter = filtro.toLowerCase();
        const datosFiltrados = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} campos encontrados</h4>
<div className="form-group">
<label className="form-control-label">Filtrar resultados</label>
<input className="form-control" placeholder="Escribe palabras clave" name={input} onChange={this.handleChange} />
</div>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{datosFiltrados.length} Coincicencias</label></Col>:false}


<Table responsive hover>
  <thead>
    <tr>
    <th>Título</th>
    <th>Slug</th>
    <th>Valores</th>
    <th>Tipo</th>
    <th>Creado</th>
    </tr>
  </thead>
  <tbody>
    { datosFiltrados.map((file,i)=>{
        return <tr key={`user${i}`}>
            <th>{file.titulo}
            <p className="link" onClick={()=>this.setState({ nuevoregistro: file, showcreacion: true })}>Ver detalles</p>
            </th>
            <th>{file.slug}</th>
            <th>{file.valores.length}</th>
            <th>{file.tipo}</th>
            <th>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{fechaATexto(file.createdAt,'es-ES')}</Tooltip>}>
            <Moment locale="ES" fromNow>{file.createdAt}</Moment>
            </OverlayTrigger>
                </th>
        </tr>
    }) }
  </tbody>
</Table>
        </div>
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Campos personalizados </h1>
      </div>

      {this.mostrarCampos()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(CustomFields);