import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login';
import store from './redux/store';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import Roles from './components/Roles';
import Permisos from './components/Permisos';
import Customers from './components/Customers';
import CustomerDetails from './components/CustomerDetails';
import Emails from './components/Emails';
import Listas from './components/Listas';
import ListaDetalle from './components/ListaDetalle';
import PlantillasEmails from './components/PlantillasEmails';
import EmailsNew from './components/EmailsNew';
import PlantillaEmailNew from './components/PlantillaEmailNew';
import PlantillaEmailDetail from './components/TemplateDetails'
import ListsNew from './components/ListsNew';
import EmailDetails from './components/EmailDetails';
import Settings from './components/Settings';
import CustomFields from './components/CustomFields';

require('dotenv').config()

const App = (
  <Provider store={store}>
    <BrowserRouter>
    <Switch>
    
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/customers" component={Customers} />
      <PrivateRoute exact path="/customer-:id" component={CustomerDetails} />
      <PrivateRoute exact path="/emails" component={Emails} />
      <PrivateRoute exact path="/emails-new" component={EmailsNew} />
      <PrivateRoute exact path="/templates-emails" component={PlantillasEmails} />
      <PrivateRoute exact path="/message-:id" component={EmailDetails} />
      <PrivateRoute exact path="/templates-emails-new" component={PlantillaEmailNew} />
      <PrivateRoute exact path="/template-:id" component={PlantillaEmailDetail} />
      <PrivateRoute exact path="/lists" component={Listas} />
      <PrivateRoute exact path="/lista-:id" component={ListaDetalle} />
      <PrivateRoute exact path="/list-new" component={ListsNew} />
      <PrivateRoute exact path="/custom-fields" component={CustomFields} />
      <PrivateRoute exact path="/roles" component={Roles} />
      <PrivateRoute exact path="/permisos" component={Permisos} />
      <Route path="/login" component={Login} />
    </Switch>
  </BrowserRouter>
  </Provider>
) 

ReactDOM.render(App, document.getElementById('root'))
reportWebVitals();
