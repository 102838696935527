import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'
import { fechaATexto } from '../../lib/helpers/helpers'
import { Col, Row, Button, Card} from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import * as XLSX from 'xlsx'
import { checkForDuplicatesEmails, sliceIntoChunks } from '../../lib/helpers/helpers'

class ListaDetalle extends Component {
    constructor(props){
        super(props)
        this.state = {
            idlista: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            lista: false,
            loadingLista: true,
            usuario: {},
            usuarios: [],
            tipousuario: '',
            showcreacion: false,
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            filtrousuario: '',
            agregando: false,
            nombre_agregar: '',
            email_agregar: '',
            resultados_excel: [],
            loadingMaster: false,
            textLoadingMaster: '',
            eliminando: false
            

        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleInputChangeExcel = this.handleInputChangeExcel.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user, idlista } = this.state
        this.getLista(idlista)
        document.addEventListener('scroll', this.trackScrolling);
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }

      validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      }

    getLista(id){
        this.setState({ loadingLista: true })
        return fetch(`${data.urlapi}/api/listas/detail?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingLista: false, lista: res })
        })
        .catch(error => {
            this.setState({ loadingLista: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }


    subirUsuarios(){
        const { user, hojas } = this.state
        this.setState({ guardando: true })

        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_CrearUsuariosBatch',{
            method: 'POST',
            body: JSON.stringify({
                usuarios: hojas,
                propietario: user.propietario
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ guardando: false, hojas: [] })
            toast.success('Usuarios cargados exitosamente', this.state.toaststyle )
            this.getListas(user)
        })
        .catch(error => {
            this.setState({ guardando: false })
            return toast.error('Error al consultar la información, intente nuevamente', this.state.toaststyle)
        })
    }

    mostrarLista(){
        const { loadingLista, lista, filtrousuario, loadingMaster, textLoadingMaster } = this.state

        if(loadingLista){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        if(loadingMaster){
            return <div>
                <Spinner animation="border" />
                <h3>{textLoadingMaster}</h3>
            </div>
        }

        if(!lista) return <h2>Esta lista no existe</h2>

        return <div>

            <h1><i className="far fa-list-alt"></i> {lista.titulo}</h1>
            <p className="mb-0"><i class="fas fa-desktop"></i> Datos basados en <b>{lista.subtipo}</b></p>
            {this.showByTipo(lista.tipo_creacion)}
            
            {this.mostrarDatosListas(lista.usuarios, filtrousuario, 'filtrousuario')}

        </div>

    }

    showByTipo(text){
        if(!text) return false
        if(text==='auto') return <p><i class="fas fa-robot"></i> Creada automátiamente por el sistema</p>
        if(text==='manual') return <p><i class="fas fa-mouse"></i> Creada por usuario</p>

    }


    subirUsuariosALista(idlista,usuarios){

        return fetch(`${data.urlapi}/api/listas/adduserstolist`,{
            method: 'POST',
            body: JSON.stringify({ idlista, usuarios }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(async res =>  res)
        .catch(error => {
            toast.error("Error al agregar usuarios a la lista, intente nuevamente", this.state.toaststyle)
        })
    }


    async agregarContactosALista(){
        const { usuarios, idlista } = this.state
        if(usuarios.length < 1) return toast.error('No hay usuarios para la lista', this.state.toaststyle)
        this.setState({ loadingMaster: true, textLoadingMaster: 'Espere un momento...' })
        const arraydividido = await sliceIntoChunks(usuarios, 300)

        const recorrer = await Promise.all( await arraydividido.map(async users => {
            const procesar = await this.subirUsuariosALista(idlista, users)
        }) )
        
        this.setState({ loadingMaster: false, textLoadingMaster: '', usuarios: [] })
        this.getLista(idlista)
        return toast.success('Proceso finalizado', this.state.toaststyle)
    }

    formularioAgregar(){
        const { agregando, usuarios } = this.state

        if(agregando ){
            return <div>
                <Spinner animation="border" />
                <h3>Espere un momento...</h3>
            </div>
        }

        return <div className="mb-3">


<Tabs defaultActiveKey="agregarindividual" id="uncontrolled-tab-example" className="mb-3">
<Tab eventKey="agregarindividual" title="Agregar usuarios individualmente a esta lista">
<Card >
        <Card.Body>

            
<Row>
            <Col xs={12}><h4>Agregar nuevo usuario a esta lista</h4></Col>
            
            <Col xs={3}>
            <label className="form-control-label">Nombre</label>
            <input className="form-control mb-3" name="nombre_agregar" onChange={this.handleChange} />
            </Col>

            <Col xs={3}>
            <label className="form-control-label">Email</label>
            <input className="form-control mb-3" name="email_agregar" onChange={this.handleChange} />
            </Col>

            <Col xs={3}>
            <label className="form-control-label d-block">Click para agregar</label>
            <Button size="sm" variant="primary" onClick={()=>this.agregarEmailALista()} >AGREGAR</Button>
            </Col>
            
        </Row>

        </Card.Body>

    </Card>
  </Tab>
<Tab eventKey="masivo" title="Agregar usuarios por excel a esta lista">

<Card>
<Card.Body>

            
<Row>
            <Col xs={12}>
                <h4><i className="far fa-file-excel"></i> Agregar usuarios masivamente</h4>
                <p>Tu archivo debe contener las siguientes columnas en minúscila: <b>email, nombre, razonsocial, telefono, rut</b>, solo será obligatoria la columna <b>email</b></p>
            </Col>
            
            <Col xs={12}>
    <label className="form-control-label">Carga tu archivo excel con los contactos</label>
    		<input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChangeExcel} 
            placeholder="Archivo de excel" 
    /> 
<hr />
{ usuarios.length > 0 ? <div>
    <h3>{usuarios.length} Contactos agregados</h3>
    <Button size="sm" className="mb-3" variant="success" onClick={()=>this.agregarContactosALista()} >AGREGAR</Button>
</div> : false }
{this.mostrarResultadosExcel()}
    
</Col>

        </Row>

        </Card.Body>

    </Card>

  </Tab>
  
  
</Tabs>


        

        </div>
    }


    handleInputChangeExcel(event){
        const { lista } = this.state 
        this.setState({ hojas: [], empresas: [] })
        const target = event.target
        const name = target.name
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true, resultados_excel: []})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos', this.state.toaststyle)
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos', this.state.toaststyle)
            if(hojas[0].length < 1) return toast.error('No se encontraron datos', this.state.toaststyle)
            if(!hojas[0][0]) return toast.error('No se encontraron datos', this.state.toaststyle)
            
            let errores = []
            let tarifas = []
            let columnasrequeridas = [
                'email',
            ]
            let resultados_excel = []
            
            columnasrequeridas.map(nombre => {
                if(!hojas[0][0][nombre]) errores.push(`Falta la columna ${nombre} en tu formato`)
            })
            
            if(errores.length > 0) return toast.error(errores.join(', '), this.state.toaststyle) // SI HAY ERRORES DETENER

            let registros = []
            let emails_invalidos = []
            let emails_duplicados = []
            
            hojas[0].map((row,irow) => {
                if(!row.email) return false
                if(this.validateEmail(row.email) !== true) return emails_invalidos.push(row.email)

                const check_ya_existe_en_lista = checkForDuplicatesEmails(row.email,lista.usuarios)
                if(check_ya_existe_en_lista) return emails_duplicados.push(row.email)

                const agregar = {
                    email: row.email,
                    nombre: row.nombre ? row.nombre : '',
                }

                registros.push(agregar)
            })

            if(emails_invalidos.length > 0) resultados_excel.push(`Se omitieron ${emails_invalidos.length} emails inválidos`)
            if(emails_duplicados.length > 0) resultados_excel.push(`Se omitieron ${emails_duplicados.length} emails que ya existían en esta lista`)

            resultados_excel.push(`Se encontraron ${registros.length} emails válidos`)

            return this.setState({ usuarios: registros, resultados_excel })

          }
        }
    }

    agregarEmailALista(){
        const { email_agregar, nombre_agregar, idlista } = this.state
        if(!email_agregar) return toast.error('Inserte un email', this.state.toaststyle)
        if(this.validateEmail(email_agregar) !== true) return toast.error('Email inválido', this.state.toaststyle)
        this.setState({ agregando: true })
        return fetch(`${data.urlapi}/api/listas/agregar-emails-lista`,{
            method: 'POST',
            body: JSON.stringify({
                idlista,
                emails: [ { email: email_agregar, nombre: nombre_agregar } ] 
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                this.setState({ agregando: false })
                 return toast.error('Ocurrió un error inesperado, intenta recargar la página', this.state.toaststyle)
            }
            this.setState({ agregando: false })
            this.getLista(idlista)
            return toast.success('Usuarios cargados exitosamente', this.state.toaststyle )
        })
        .catch(error => {
            this.setState({ agregando: false })
            return toast.error('Error al consultar la información, intente nuevamente', this.state.toaststyle)
        })
    }


    mostrarResultadosExcel(){
        const { resultados_excel } = this.state

        if(resultados_excel.length > 0) return resultados_excel.map((observ,i) => {
            return <p key={`ob${i}`}>{observ}</p>
        })
    }

    mostrarDatosListas(datos, filtro, input){
        const { eliminando } = this.state
        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
{this.formularioAgregar()}
<h4>{datos.length} usuarios</h4>
<div className="form-group">
<label className="form-control-label">Filtrar resultados</label>
<input className="form-control" name={input} onChange={this.handleChange} />
</div>

{filtro !== ''  ? <Col xs={12}><h5 className="badge bg-success text-light">{listasFiltradas.length} Coincicencias</h5></Col>:false}


<Table responsive hover>
  <thead>
    <tr>
    <th>Nombre</th>
    <th>Email</th>
    <th>Agregado</th>
    <th></th>
    </tr>
  </thead>
  <tbody>
    { listasFiltradas.map((lista,i)=>{
        return <tr key={`user${i}`}>
            <th>{lista.nombre}</th>
            <th>{lista.email}
                {/*<button className="d-block btn btn-sm btn-outline-danger" >REMOVER</button>*/}
            </th>
            <th>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{fechaATexto(lista.createdAt,'es-ES')}</Tooltip>}>
            <Moment locale="ES" fromNow>{lista.createdAt}</Moment>
            </OverlayTrigger>
                </th>
                <th>{ eliminando ? <Spinner animation="border" /> : <Button size="sm" variant="outline-danger" onClick={()=>this.eliminarLista(lista._id)} >ELIMINAR</Button>} </th>
        </tr>
    }) }
  </tbody>
</Table>
        </div>
    }

    eliminarLista(id){
        const { lista } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/listas/delete-email-from-list?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = lista.usuarios.findIndex(li => li._id === id)
            if(i > -1){
                lista.usuarios.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ lista })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2 d-block">Detalle de lista </h1>
      </div>
        <Link to="/lists"><span style={{ fontSize: 13 }}>Atrás</span></Link>
            {this.mostrarLista()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(ListaDetalle);