import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'
import { Row, Col, Card,  Container, Form, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { fechaATexto } from '../../lib/helpers/helpers'
import { PieChart } from 'react-minimal-pie-chart';

class EmailDetails extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            emails: [],
            loadingEmails: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            loadingListas: true,
            listas: [],
            nuevoregistro: {
                usuarios: []
            },
            plantillas_email: [],
            guardado: false,
            guardandoPlantilla: false,
            idemail: this.props.match.params.id,
            filtrousuario: '',
            actualizandoestado: false

        }
        this.referencia = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeRegistro = this.handleChangeRegistro.bind(this)
    }

    componentDidMount(){
        const { idemail } = this.state
        this.getEmail(idemail)
    }

    getEmail(id){

        this.setState({ loadingListas: true })
        return fetch(`${data.urlapi}/api/emails/details?id=${id}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingListas: false, nuevoregistro: res })
        })
        .catch(error => {
            console.log(error)
            this.setState({ loadingListas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeRegistro(e){
        const { name, value } = e.target
        const { nuevoregistro } = this.state
        nuevoregistro[name] = value
        return this.setState({ nuevoregistro })
    }
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    getListas(web){

        this.setState({ loadingListas: true })
        return fetch(`${data.urlapi}/api/listas/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingListas: false, listas: res })
        })
        .catch(error => {
            this.setState({ loadingListas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }
    
    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, usuario, areas, cargos, guardandocambios, roles } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { usuario._id ? <h3>Editar {usuario.nombres ? usuario.nombres : 'usuario'} </h3> : <h3>Crear usuario</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombres</label>
    <input name="nombres" className="form-control" value={usuario.nombres ? usuario.nombres : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido paterno</label>
    <input name="apellidopaterno" className="form-control" value={usuario.apellidopaterno ? usuario.apellidopaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido materno</label>
    <input name="apellidomaterno" className="form-control" value={usuario.apellidomaterno ? usuario.apellidomaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Rut</label>
    <input name="rut" className="form-control" value={usuario.rut ? usuario.rut : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Fecha nacimiento</label>
    <input name="fechanacimiento" type="date" className="form-control" value={usuario.fechanacimiento ? usuario.fechanacimiento : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Email</label>
    <input name="email" className="form-control" value={usuario.email ? usuario.email : ''} disabled={ usuario._id ? true : false } onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Password</label>
    <input name="password" className="form-control" value={usuario.password ? usuario.password : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Tipo</label>
    <select name="tipo" className="form-control" value={usuario.tipo ? usuario.tipo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            roles.length > 0 ? roles.map(rol => {
                return <option value={rol.slug}> {rol.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" value={usuario.area ? usuario.area : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo" className="form-control" value={usuario.cargo ? usuario.cargo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={12} style={{ padding: 15 }}>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ usuario._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    <p className="m-0 onclick" onClick={()=>this.setState({ usuario: {} })}>Reiniciar formulario</p>
</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }


    newPlantillas(payload){
        this.setState({ guardandoPlantilla: true })
        return fetch(`${data.urlapi}/api/plantillas-emails/new`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            toast.success('Guardado exitosamente', this.state.toaststyle)
            this.setState({ guardandoPlantilla: false, guardado: true })
        })
        .catch(error => {
            this.setState({ guardandoPlantilla: false })
            toast.error("Error al realizar la operación, intente nuevamente", this.state.toaststyle)
        })
    }

    exportTemplate = async () => {
        await this.referencia.current.editor.exportHtml(async (data) => {
          const { design, html } = data;
          const { nuevoregistro, user } = this.state

          if(!nuevoregistro.titulo) return toast.error('Falta el título de tu plantilla', this.state.toaststyle)
          if(!design) return toast.error('Falta configurar tu plantilla', this.state.toaststyle)
          if(!html) return toast.error('Falta configurar tu plantilla', this.state.toaststyle)
          nuevoregistro.json_design = design
          nuevoregistro.html_text = html
          nuevoregistro.bloques = []
          nuevoregistro.creador = {
              nombres: user.nombres,
              email: user.email
          }
          nuevoregistro.web = user.web
          const guardar = await this.newPlantillas(nuevoregistro)

        });
      };


      tiempoString(string){
        if(string==='now') return 'De inmediato'
        return string
      }


      cambiarEstado(id,estado){

        const enviar = estado === true ? '0' : '1'
        const { nuevoregistro } = this.state
        this.setState({ actualizandoestado: true })
        return fetch(`${data.urlapi}/api/emails/desactivate?id=${id}&estado=${enviar}`)
        .then(res => res.json())
        .then(res => {
            nuevoregistro.enviar = estado === true ? false : true
            this.setState({ actualizandoestado: false, nuevoregistro })
        })
        .catch(error => {
            this.setState({ actualizandoestado: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

      }

    mostrarPlantilla(){
        const { guardando, loadingListas, guardado, actualizandoestado, emails, nuevoregistro, plantillas_email, listas, filtrousuario, tipousuario } = this.state

        if(loadingListas) return <div>
            <Spinner animation="border" />
            <h3>Generando informe</h3>
        </div>
        
        if(guardado) return <Row className="justify-content-md-center mt-7">
        <Col md={6} className="text-center ">
        <img className="iconsmall mb-3" src="check.gif" />
        <h5>Creado exitosamente</h5>
        <p className="nomargin">Tu plantilla fue creada, ahora puedes utilizarla al crear mensajes de correo en el menú <b>Emails</b></p>
        <Link to="/templates-emails">Atrás</Link>
        </Col>
        </Row> 

let options = []
let plantillas = []

listas.map(opcion => {
    options.push({ value: opcion._id, label: `${opcion.titulo} · ${opcion.subtipo}` })
})
plantillas_email.map(opcion => {
    plantillas.push({ value: opcion._id, label: opcion.titulo })
})

const filtrouser = filtrousuario.toLowerCase();
const usuariosfiltrados = nuevoregistro.usuarios ? nuevoregistro.usuarios.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(filtrouser)
            );
        }) : []


if(guardando){
    return <div>
                <Spinner animation="border" />
                <h3>Espere un momento...</h3>
            </div>
        }
        
        const lowercasedFilter = filtrousuario.toLowerCase();
        const listasFiltradas = emails.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
                );
            });
            const filtradosportipo = tipousuario ? listasFiltradas.filter(user => user.tipo===tipousuario) : listasFiltradas
            
            let enviados = 0
            let omitidos = 0
            let cola = 0
            let total_correos = 0

            let recibidos = 0
            let rebotados = 0
            let click = 0
            let open = 0
            let unsubscribe = 0
            let spamreport = 0

            /*
            if(nuevoregistro.usuarios){
                if(Array.isArray(nuevoregistro.usuarios) !== false){
                    if(nuevoregistro.usuarios.length > 0){
                        const conteo = nuevoregistro.usuarios.filter(user => user.enviado === 1)
                        enviados = conteo.length
                        nuevoregistro.usuarios.map(user => {
                            if(user.enviado !== 1) return false
                            recibidos = recibidos + (user.delivered === 1 ? 1 : 0)
                            rebotados = rebotados +  (user.bounce === 1 ? 1 : 0)
                            click = click + (user.click === 1 ? 1 : 0)
                            open = open + (user.open === 1 ? 1 : 0)
                            unsubscribe = unsubscribe + (user.unsubscribe === 1 ? 1 : 0)
                            spamreport = spamreport + (user.spamreport === 1 ? 1 : 0)
                        })
                    }
                }
            }
            */

            if(nuevoregistro.reporte){
                if(typeof nuevoregistro.reporte === 'object'){
                    if(nuevoregistro.reporte.bounce) rebotados = nuevoregistro.reporte.bounce
                    if(nuevoregistro.reporte.click) click = nuevoregistro.reporte.click
                    if(nuevoregistro.reporte.delivered) recibidos = nuevoregistro.reporte.delivered
                    if(nuevoregistro.reporte.open) open = nuevoregistro.reporte.open
                    if(nuevoregistro.reporte.spamreport) spamreport = nuevoregistro.reporte.spamreport
                    if(nuevoregistro.reporte.unsubscribe) unsubscribe = nuevoregistro.reporte.unsubscribe
                    if(nuevoregistro.reporte.enviados) enviados = nuevoregistro.reporte.enviados
                    if(nuevoregistro.reporte.omitidos) omitidos = nuevoregistro.reporte.omitidos
                    if(nuevoregistro.reporte.total_cola) cola = nuevoregistro.reporte.total_cola
                    if(nuevoregistro.reporte.total) total_correos = nuevoregistro.reporte.total
                }
            }

            const porcentaje_cola = Math.round((total_correos-enviados)/total_correos*100)
            const porcentaje_enviado = Math.round(enviados/total_correos*100)
            const porcentaje_click = Math.round(click/enviados*100)
            const porcentaje_abiertos = Math.round(open/enviados*100)
            const porcentaje_recibido = Math.round(recibidos/enviados*100)
            const porcentaje_rebotados = Math.round(rebotados/enviados*100)

            const datos = [
                { value: porcentaje_recibido, color: '#9fce1c' },
                { value: porcentaje_rebotados, color: '#ce1717' },
            ]

            const datos_master = [
                { value: click, color: '#eabc07' },
                { value: open, color: '#9807ea' },
                { value: recibidos, color: '#9fce1c' },
                { value: rebotados, color: '#ea0707' },
            ]
            const total_master = click + open + recibidos + rebotados


        return <Card style={{ width: '100%' }}>
        <Card.Body>
         
        <Tabs defaultActiveKey="mensaje" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="mensaje" title="Detalles del mensaje">
<Row>

<Col xs={12}><h4><i className="fas fa-ellipsis-h"></i> Configuración general</h4></Col>

<Col md={3}>
  <label className="form-control-label">Título</label>
  <h6>{nuevoregistro.titulo}</h6>
  </Col>

  <Col md={3}>
  <label className="form-control-label">Lista de usuarios</label>
  { nuevoregistro.idlista === 'todos' ? <p>Todos los usuarios</p> : <p><Link to={`/lista-${nuevoregistro.idlista}`} >{nuevoregistro.idlista}</Link></p> }
  
  </Col>

  <Col md={2}>
  <label className="form-control-label">Tiempo</label>
  <h6>{this.tiempoString(nuevoregistro.tiempo)}</h6>
  </Col>

  <Col md={2}>
  <label className="form-control-label">Creado</label>
  <h6>
  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{fechaATexto(nuevoregistro.createdAt,'es-ES')}</Tooltip>}>
  <Moment locale="ES" fromNow>{nuevoregistro.createdAt}</Moment>
  </OverlayTrigger>
  </h6>
  </Col>

  <Col md={2}>
      { actualizandoestado ? <Spinner animation="border" /> : <button className={`btn btn-sm ${nuevoregistro.enviar===true ? 'btn-outline-danger' : 'btn-outline-success' }`} onClick={()=>this.cambiarEstado(nuevoregistro._id, nuevoregistro.enviar)} >{nuevoregistro.enviar===true ? 'CANCELAR ENVÍO' : 'REANUDAR ENVÍO' } </button>  }
  </Col>

  {
      nuevoregistro.tiempo === 'programar' ? <Col md={3}>
      <label className="form-control-label">Fecha de envío</label>
      <input style={{ padding: 7 }} className="form-control" type="date" name="tiempo" value={nuevoregistro.tiempo} onChange={this.handleChangeRegistro} />
      </Col> : false
  }

  
  <Col md={12}><hr /></Col>

  <Col xs={12}><h4><i className="far fa-envelope"></i> Configuración de email</h4></Col>

  <Col md={3}>
  <label className="form-control-label">Asunto email</label>
  <h6>{nuevoregistro.asunto}</h6>
  </Col>

  <Col md={3}>
  <label className="form-control-label">Remitente email</label>
  <h6>{nuevoregistro.remitente}</h6>
  </Col>

  <Col md={3}>
  <label className="form-control-label">Email remitente</label>
  <h6>{nuevoregistro.email_remitente}</h6>
  </Col>

  <Col md={3}>
  <label className="form-control-label">Responder a</label>
  <h6>{nuevoregistro.responder_a}</h6>
  </Col>

  <Col md={12}><hr /></Col>

  <Col md={6}>
      <h4><i className="far fa-chart-bar"></i> Estadísticas</h4>

      <Row>

      <Col xs={4} md={3}>
  <label className="form-control-label">Enviados</label>
  <h6>{enviados}</h6>
  </Col>

  
  <Col xs={4} md={3}>
  <label className="form-control-label">Entregados</label>
  <h6>{recibidos}</h6>
  </Col>
  
  <Col xs={4} md={3}>
  <label className="form-control-label">Rebotes</label>
  <h6>{rebotados}</h6>
  </Col>
  
  <Col xs={4} md={3}>
  <label className="form-control-label">Clicks</label>
  <h6>{click}</h6>
  </Col>
  
  <Col xs={4} md={3}>
  <label className="form-control-label">Abiertos</label>
  <h6>{open}</h6>
  </Col>
  
  <Col xs={4} md={3}>
  <label className="form-control-label">Desuscritos</label>
  <h6>{unsubscribe}</h6>
  </Col>
  
  <Col xs={4} md={3}>
  <label className="form-control-label">Reportados Spam</label>
  <h6>{spamreport}</h6>
  </Col>


  <Col xs={4} md={3}>
  <label className="form-control-label">En cola</label>
  <h6>{cola}</h6>
  </Col>

  <Col xs={4} md={3}>
  <label className="form-control-label">Omitidos</label>
  <h6>{omitidos}</h6>
  </Col>

  <Col xs={4} md={4}>
  <label className="form-control-label">Promedio apertura</label>
  <h6>{Math.round(open/enviados*100)}%</h6>
  </Col>

  <Col xs={4} md={4}>
  <label className="form-control-label">Promedio Click</label>
  <h6>{Math.round((click/total_correos)*100)}%</h6>
  </Col>

  <Col xs={12}> <hr /> </Col>

  <Col xs={12}>

      <Row>
          <Col md={3} xs={4}>
          <PieChart
          rounded
          animated
          lineWidth={20}
      paddingAngle={18}
      label={({ dataEntry }) => `${dataEntry.value}%`}
      labelStyle={(index) => ({
        fill: datos[index].color,
        fontSize: '15px',
        fontFamily: 'sans-serif',
      })}
  data={datos}
/>
          </Col>
          <Col md={9} xs={8}>
              <h5 className="nomargin">Entregados vs rebotes</h5>
              <p>Datos en base al 100% de los emails enviados.</p>
              <p className="nomargin"><i className="fas fa-circle" style={{ color: '#9fce1c'}}></i> Entregados </p>
              <p className="nomargin"><i className="fas fa-circle" style={{ color: '#ce1717'}}></i> Rebotes </p>
          </Col>
      </Row>
  
  </Col>
  
  <Col xs={12}> <hr /> </Col>


  <Col xs={12}>

      <Row>
          <Col md={5} xs={12}>
          <PieChart
          totalValue={total_master}
          animated
          lineWidth={14}
      label={({ dataEntry }) => dataEntry.value }
      labelStyle={(index) => ({
        fill: datos_master[index].color,
        fontSize: '5px',
        fontFamily: 'sans-serif',
      })}
  data={datos_master}
/>
          </Col>
          <Col md={7} xs={8}>
              <h5 className="nomargin">Reporte</h5>
              <p>Datos en base al 100% de los emails enviados.</p>
                <p className="nomargin" style={{ color: '#9fce1c'}} ><i className="fas fa-circle" style={{ color: '#9fce1c'}}></i> Recibidos </p>
                <p className="nomargin" style={{ color: '#ea0707'}} ><i className="fas fa-circle" style={{ color: '#ea0707'}}></i> Rebotados </p>
                <p className="nomargin" style={{ color: '#9807ea'}} ><i className="fas fa-circle" style={{ color: '#9807ea'}}></i> Abiertos </p>
                <p className="nomargin" style={{ color: '#eabc07'}} ><i className="fas fa-circle" style={{ color: '#eabc07'}}></i> Clicks </p>
          </Col>
      </Row>
  
  </Col>
  
  <Col xs={12}> <hr /> </Col>

  <Col  xs={12}>

      <Row>
      <Col style={{ marginBottom: 20, textAlign:'center' }} md={2} xs={4}>
              <p className="nomargin"><b>En cola</b></p>
          <PieChart
      data={[{ title: `${porcentaje_cola}%`, value: porcentaje_cola, color: '#000' },{ title: '', value: 100-porcentaje_cola, color: '#e3e3e3' }]}
      totalValue={100}
      lineWidth={20}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={{
        fontSize: '25px',
        fontFamily: 'sans-serif',
        fill: '#000',
      }}
      labelPosition={0}
    />
          </Col>
      <Col style={{ marginBottom: 20, textAlign:'center' }} md={2} xs={4}>
              <p className="nomargin"><b>Enviado</b></p>
          <PieChart
      data={[{ title: `${porcentaje_enviado}%`, value: porcentaje_enviado, color: '#007bff' },{ title: '', value: 100-porcentaje_enviado, color: '#e3e3e3' }]}
      totalValue={100}
      lineWidth={20}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={{
        fontSize: '25px',
        fontFamily: 'sans-serif',
        fill: '#007bff',
      }}
      labelPosition={0}
    />
          </Col>
          <Col style={{ marginBottom: 20, textAlign:'center' }} md={2} xs={4}>
              <p className="nomargin"><b>Abierto</b></p>
          <PieChart
      data={[{ title: `${Math.round(open/enviados*100)}%`, value: porcentaje_abiertos, color: '#9807ea' },{ title: '', value: 100-porcentaje_abiertos, color: '#e3e3e3' }]}
      totalValue={100}
      lineWidth={20}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={{
        fontSize: '25px',
        fontFamily: 'sans-serif',
        fill: '#9807ea',
      }}
      labelPosition={0}
    />
          </Col>
          <Col style={{ marginBottom: 20, textAlign:'center' }} md={2} xs={4}>
              <p className="nomargin"><b>Click</b></p>
          <PieChart
      data={[{ title: `${Math.round(click/enviados*100)}%`, value: porcentaje_click, color: '#eabc07' },{ title: '', value: 100-porcentaje_click, color: '#e3e3e3' }]}
      totalValue={100}
      lineWidth={20}
      label={({ dataEntry }) => dataEntry.title}
      labelStyle={{
        fontSize: '25px',
        fontFamily: 'sans-serif',
        fill: '#eabc07',
      }}
      labelPosition={0}
    />
          </Col>
      </Row>
  
  </Col>
  
  <Col xs={12}> <hr /> </Col>
      
      </Row>    
      
  </Col>


  <Col md={6}>
      <h4><i className="far fa-object-group"></i> Previsualización</h4>
      <hr className="hr" />
  <div dangerouslySetInnerHTML={{__html: nuevoregistro.html_message }} /> 
      </Col>

</Row>
  </Tab>
  { /* <Tab eventKey="usuarios" title="Destinatarios">

<h4>{nuevoregistro.usuarios.length} Usuarios </h4>

<div className="form-group">
<label className="form-control-label d-block" >Filtrar</label>
<input className="form-control" placeholder="Buscar un email" name="filtrousuario" onChange={this.handleChange} />
</div>

<Table responsive hover>
    <thead>
    <tr>
            <th>Email</th>
            <th>Enviado</th>
            <th>Entregado</th>
            <th>Rebote</th>
            <th>Click</th>
            <th>Abierto</th>
            <th>Desuscrito</th>
            <th>Reportado spam</th>
    </tr>
    </thead>
<tbody>
{
    usuariosfiltrados.map((user,iuser) => {
        return <tr key={`user${iuser}`}>
            <th>{user.email}</th>
            <th>{this.showStatus(user)}</th>
            <th>{user.delivered > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
            <th>{user.bounce > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
            <th>{user.click > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
            <th>{user.open > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
            <th>{user.unsubscribe > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
            <th>{user.spamreport > 0 ? <i  className="text-success fa fa-check-circle"></i> : <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> }</th>
        </tr>

    })
}
</tbody>
</Table>
  </Tab> */ }
</Tabs>

          

        </Card.Body>
      </Card>
    }

    showStatus(user){
        if(user.enviado === 1){
            return <div>
            <i style={{ marginRight: 2 }} className="text-success fa fa-check-circle"></i>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{fechaATexto(user.createdAt,'es-ES')}</Tooltip>}>
<Moment locale="ES" fromNow>{user.createdAt}</Moment>
</OverlayTrigger>
        </div> 
        } else if(user.enviado === 5){
            return <div>
                <p className="mb-0">Omitido por políticas de frecuencia</p>
            </div>
        }


        return <i style={{ color: '#e1dede' }} className="fas fa-clock"></i> 
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>

    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">  Nueva plantilla </h1>
      </div>
   <Link className="d-block mb-3" to="/emails">Atrás</Link>
      {this.mostrarPlantilla()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EmailDetails);