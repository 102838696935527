import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'
import { Row, Col, Card,  Container, Form, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'

class Customers extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            usuarios: [],
            loadingUsuarios: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            skipusers: 0,
            loadingMore: false,
            report: false,
            searching: false

        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)

    }

    handleInputChange (event) {
        const { areas, cargos} = this.state
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
          [name]: value
        })
        let hojas = []
        if (name === 'file') {
            this.setState({procesandoexcel:true})
          let reader = new FileReader()
          reader.readAsArrayBuffer(target.files[0])
          reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            
            const requeridos = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                'area',
                'cargo'
            ]

            const convertirtexto = [
                'nombres',
                'apellidopaterno',
                'apellidomaterno',
                'rut',
                'fechanacimiento',
                'email',
                'password',
                //'area',
                //'cargo'
            ]
            const columnasincompletas = []
            hojas[0].forEach((row,i) => {
                const faltantes = []
                requeridos.map(req => {
                    if(!row[req]) faltantes.push(req)
                })
                if(row.rut) hojas[0][i].rut = row.rut.replace(/[\W_]+/g, "")

                convertirtexto.map(campo => {
                    if(row[campo]) hojas[0][i][campo] = row[campo].toString()
                })

                if(row.area){
                    const iarea = areas.findIndex(ar => ar.nombre === row.area)
                    if(areas[iarea]){
                        hojas[0][i].area = areas[iarea]._id['$oid']
                    } else {
                        columnasincompletas.push(`El área ${row.area} no existe, columna ${i+1}`)
                    }
                }

                if(row.cargo){
                    const icargo = cargos.findIndex(ar => ar.nombre === row.cargo)
                    if(cargos[icargo]){
                        hojas[0][i].cargo = cargos[icargo]._id['$oid']
                    } else {
                        columnasincompletas.push(`El cargo ${row.cargo} no existe, columna ${i+1}`)
                    }
                }

                if(faltantes.length > 0) columnasincompletas.push(`La columna ${i+1} le faltan campos: ${faltantes.join(', ')}`)
            })

            if(columnasincompletas.length > 0){
                this.setState({ procesandoexcel: false })
                columnasincompletas.push('Corrige el documento e inténtalo de nuevo')
                return toast.error( columnasincompletas.join('. '), this.state.toaststyle )
            }
            
            this.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas[0],
              procesandoexcel: false
            })
          }
        }
      }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    componentDidMount(){
        const { user } = this.state
        this.getCustomers(user)
        this.getReport(user.web)
        // document.addEventListener('scroll', this.trackScrolling);

    }

    getReport(web){
        this.setState({ loadingReport: true })
        return fetch(`${data.urlapi}/api/reports/customers?web=${web}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingReport: false, report: res })
        })
        .catch(error => {
            this.setState({  loadingReport: false })
        })
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
       document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
           toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }
    
    guardarCrear(){
        const { usuario, user } = this.state

        return false
        if(!usuario.nombres||!usuario.apellidopaterno||!usuario.apellidomaterno||!usuario.rut||!usuario.fechanacimiento||!usuario.tipo||!usuario.cargo){
            return alert("Todos los campos son requeridos")
        }
        usuario.propietario = user.propietario
        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_editarCrearUsuario',{
            method: 'POST',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = usuario._id ? 'Usuario modificado exitosamente' : 'Usuario creado exitosamente'
            this.setState({ guardandocambios: false, textguardado: texto })
            this.getCustomers(user)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    abortController = new window.AbortController();

    fetchUsuarios(web, loading){
        const { skipusers, usuarios } = this.state
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/customers/list?web=${web}&skip=${skipusers}`)
        .then(res => res.json())
        .then(res => {
            if(res.length > 0) this.setState({ skipusers: skipusers + 20 })
            res.map(user => {
                usuarios.push(user)
            })
            this.setState({ [loading]: false, usuarios })
        })
        .catch(error => {
            this.setState({ [loading]: false })
        })
    }
    getCustomers(user){
        return this.fetchUsuarios(user.web,'loadingUsuarios')
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, usuario, areas, cargos, guardandocambios, roles } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { usuario._id ? <h3>Editar {usuario.nombres ? usuario.nombres : 'usuario'} </h3> : <h3>Crear usuario</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombres</label>
    <input name="nombres" className="form-control" value={usuario.nombres ? usuario.nombres : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido paterno</label>
    <input name="apellidopaterno" className="form-control" value={usuario.apellidopaterno ? usuario.apellidopaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido materno</label>
    <input name="apellidomaterno" className="form-control" value={usuario.apellidomaterno ? usuario.apellidomaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Rut</label>
    <input name="rut" className="form-control" value={usuario.rut ? usuario.rut : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Fecha nacimiento</label>
    <input name="fechanacimiento" type="date" className="form-control" value={usuario.fechanacimiento ? usuario.fechanacimiento : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Email</label>
    <input name="email" className="form-control" value={usuario.email ? usuario.email : ''} disabled={ usuario._id ? true : false } onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Password</label>
    <input name="password" className="form-control" value={usuario.password ? usuario.password : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Tipo</label>
    <select name="tipo" className="form-control" value={usuario.tipo ? usuario.tipo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            roles.length > 0 ? roles.map(rol => {
                return <option value={rol.slug}> {rol.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" value={usuario.area ? usuario.area : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo" className="form-control" value={usuario.cargo ? usuario.cargo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={12} style={{ padding: 15 }}>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ usuario._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    <p className="m-0 onclick" onClick={()=>this.setState({ usuario: {} })}>Reiniciar formulario</p>
</Col>
</Row>
                </Card.Body>
            </Card>
        }


    }

    buscarContacto(){
        const { filtrousuario, user } = this.state

        if(!filtrousuario){
            this.setState({ usuarios: [] })
            return this.getCustomers(user)
        }
        this.setState({ searching: true })
        return fetch(`${data.urlapi}/api/customers/search?web=${user .web}&email=${filtrousuario}`, { signal: this.abortController.signal })
        .then(res => res.json())
        .then(res => {
            if(res.length < 1) toast.warn('No se encontraron coincidencias', this.state.toaststyle)
            this.setState({ searching: false, usuarios: res, skipusers: 0 })
        })
        .catch(error => {
            this.setState({ searching: false })
        })
    }

    mostrarUsuarios(){
        const { loadingUsuarios, usuarios, report, searching, loadingReport, loadingMore, user, filtrousuario, textguardado, tipousuario, showcreacion } = this.state

        if(loadingUsuarios){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtrousuario.toLowerCase();
        const usuariosFiltrados = usuarios.filter(item => {
            return Object.keys(item.data).some(key =>{
                if(!item[key]) return false
                return item[key].toString().toLowerCase().includes(lowercasedFilter)
            });
        });
        const filtradosportipo = tipousuario ? usuarios.filter(user => user.tipo===tipousuario) : usuarios
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>
              { loadingReport ? <Spinner animation="border" /> : <div>
                    { report ? `${report.total} contactos encontrados` : 'Datos no disponibles' }
              </div> }
              </Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>

            { /* <Col xs={12}>
            <img src="icon3.jpg" className="iconsystem" />
            <h4>Contactos de tu web</h4>
            <hr />
            <div className="form-group">
            <label className="form-control-label">Carga tu documento excel con la base de tus contactos</label>
            <div className="mb-2">
            <a href="usuarios.xlsx" className="btn btn-sm btn-outline-warning" >DESCARGAR MODELO EXCEL</a>
            </div>
            <input 
            required 
            type="file" 
            name="file" 
            id="file" 
            className="form-control"
            onChange={this.handleInputChange} 
            placeholder="Archivo de excel" 
            />
            </div>

            {this.mostrarExcel()}
            <hr />
    </Col> */ }

            <Col md={3}>
            <label className="form-control-label">Buscar correo electrónico</label>
            <input name="filtrousuario" className="form-control mb-4" placeholder="Escribe un email" onChange={this.handleChange} />
            </Col>

            <Col md={3}>
            <label className="form-control-label d-block">Click para buscar</label>
            { searching ? <Spinner animation="border" /> : <button className="btn btn-sm btn-primary" onClick={()=>this.buscarContacto()}>BUSCAR</button> }
            </Col>

           

            {filtrousuario !== '' || tipousuario !== '' ? <Col xs={12}><label className="form-control-label">{filtradosportipo.length} Coincicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
    <th>Nombres</th>
    <th>Apellidos</th>
    <th>Usuario</th>
    <th>DNI</th>
    <th>Email</th>
    <th>Fecha de nacimiento</th>
    <th>Fecha registrado</th>
    </tr>
  </thead>
  <tbody>
    { filtradosportipo.map((usuario,i)=>{
        return <tr key={`user${i}`}>
            <th>{usuario.data.display_name}<br/>
            <Link to={`/customer-${usuario._id}`} >Ver detalles</Link>
            </th>
            <th>{usuario.data.apellidos}</th>
            <th>{usuario.data.user_nicename}</th>
            <th>{usuario.data.rut}</th>
            <th>{usuario.data.user_email}</th>
            <th>{usuario.data.date_born}</th>
            <th>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{usuario.data.user_registered}</Tooltip>}>
            <Moment locale="ES" fromNow>{usuario.data.user_registered}</Moment>

</OverlayTrigger>
                </th>
        </tr>
    }) }
  </tbody>
</Table>
{ loadingMore ? <Spinner animation="border" /> : <button className="btn btn-white" onClick={()=>this.fetchUsuarios(user.web, 'loadingMore')}>Cargar más...</button>}

        </Card.Body>
      </Card>
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Contactos </h1>
      </div>

      {this.mostrarUsuarios()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Customers);