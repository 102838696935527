import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'
import { Row, Col, Card,  Container, Form, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

class Login extends Component {
    constructor(props){
        super(props)
        userService.logout();
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleSubmit(){

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (!(username && password )) {
            return alert("Sin datos")
        }

        this.setState({ loading: true });
        userService.login(username, password )
            .then(
                user => {
                    if(!user){
                        this.setState({ errorLogin: "Email o Contraseña erroneas", loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );
    }
    
    render(){

        const {miusuario} = this.props
        const { loading, errorLogin } = this.state
        return(
            <Container>
                
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv">
            <img src="skiils-dark.png" style={{ width: 250, marginBottom: 10 }} />
         <h3>Iniciar sesión</h3>
            <Form.Group >
    <Form.Control type="email" name="username" placeholder="Email" onChange={this.handleChange}
 />
  </Form.Group>

  <Form.Group >
    <Form.Control type="password" name="password" placeholder="Contraseña" onChange={this.handleChange}
 />
    
  </Form.Group>

  <Form.Group >  
    { loading ? <Spinner animation="border" /> : <Button onClick={() => this.handleSubmit() }  block variant="primary">ENTRAR</Button> }  
            { errorLogin ? <p className="text-danger">{errorLogin}</p> : false }
  </Form.Group>

  <Link to="/">Olvidé mi contraseña</Link>

  <Form.Text className="text-muted">
      
    </Form.Text>
            </Col>
        </Row>

        
        
    </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Login);