import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { userService } from '../../services/user.service'
import { Row, Col, Card,  Container, Form, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'react-moment';
import 'moment/locale/es';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import data from '../../lib/backend/data'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { fechaATexto } from '../../lib/helpers/helpers'
import Select from 'react-select';
import EmailEditor from 'react-email-editor';


class PlantillaEmailNew extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            emails: [],
            loadingEmails: true,
            filtrousuario: '',
            usuario: {},
            tipousuario: '',
            showcreacion: false,
            hojas: [],
            procesandoexcel: false,
            guardando: false,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            loadingListas: true,
            listas: [],
            nuevoregistro: {},
            plantillas_email: [],
            guardado: false,
            guardandoPlantilla: false

        }
        this.referencia = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeRegistro = this.handleChangeRegistro.bind(this)
    }

    componentDidMount(){

          
    }

    handleChangeRegistro(e){
        const { name, value } = e.target
        const { nuevoregistro } = this.state
        nuevoregistro[name] = value
        return this.setState({ nuevoregistro })
    }
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUsuario(e){
        const { name, value } = e.target
        const { usuario } = this.state
        usuario[name] = value
        if(name==='rut') usuario[name] = value.replace(/[\W_]+/g, "")
        console.log(usuario)
        return this.setState({ usuario: usuario })
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
      
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('root');
        if (this.isBottom(wrappedElement)) {
          //toast.info('Final del listado alcanzado', this.state.toaststyle);
          document.removeEventListener('scroll', this.trackScrolling);
        }
      };

    getListas(web){

        this.setState({ loadingListas: true })
        return fetch(`${data.urlapi}/api/listas/list?web=${web}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingListas: false, listas: res })
        })
        .catch(error => {
            this.setState({ loadingListas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getAreas(propietario){

        this.setState({ loadingareas: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getAreas?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingareas: false, areas: res })
        })
        .catch(error => {
            this.setState({ loadingareas: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCargos(condicion){
        console.log(condicion)
        this.setState({ loadingcargos: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetCargos`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingcargos: false, cargos: res })
        })
        .catch(error => {
            this.setState({ loadingcargos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }
    
    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, usuario, areas, cargos, guardandocambios, roles } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { usuario._id ? <h3>Editar {usuario.nombres ? usuario.nombres : 'usuario'} </h3> : <h3>Crear usuario</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombres</label>
    <input name="nombres" className="form-control" value={usuario.nombres ? usuario.nombres : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido paterno</label>
    <input name="apellidopaterno" className="form-control" value={usuario.apellidopaterno ? usuario.apellidopaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Apellido materno</label>
    <input name="apellidomaterno" className="form-control" value={usuario.apellidomaterno ? usuario.apellidomaterno : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Rut</label>
    <input name="rut" className="form-control" value={usuario.rut ? usuario.rut : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Fecha nacimiento</label>
    <input name="fechanacimiento" type="date" className="form-control" value={usuario.fechanacimiento ? usuario.fechanacimiento : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Email</label>
    <input name="email" className="form-control" value={usuario.email ? usuario.email : ''} disabled={ usuario._id ? true : false } onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Password</label>
    <input name="password" className="form-control" value={usuario.password ? usuario.password : ''} onChange={this.handleChangeUsuario} />
</Col>

<Col md={3} >
    <label className="form-control-label">Tipo</label>
    <select name="tipo" className="form-control" value={usuario.tipo ? usuario.tipo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            roles.length > 0 ? roles.map(rol => {
                return <option value={rol.slug}> {rol.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Area de trabajo</label>
    <select name="area" className="form-control" value={usuario.area ? usuario.area : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            areas.length > 0 ? areas.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="cargo" className="form-control" value={usuario.cargo ? usuario.cargo : ''} onChange={this.handleChangeUsuario} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(area => {
                return <option value={area._id['$oid']}> {area.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={12} style={{ padding: 15 }}>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ usuario._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
    <p className="m-0 onclick" onClick={()=>this.setState({ usuario: {} })}>Reiniciar formulario</p>
</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }


    newPlantillas(payload){
        this.setState({ guardandoPlantilla: true })
        return fetch(`${data.urlapi}/api/plantillas-emails/new`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            toast.success('Guardado exitosamente', this.state.toaststyle)
            this.setState({ guardandoPlantilla: false, guardado: true })
        })
        .catch(error => {
            this.setState({ guardandoPlantilla: false })
            toast.error("Error al realizar la operación, intente nuevamente", this.state.toaststyle)
        })
    }

    exportTemplate = async () => {
        await this.referencia.current.editor.exportHtml(async (data) => {
          const { design, html } = data;
          const { nuevoregistro, user } = this.state

          if(!nuevoregistro.titulo) return toast.error('Falta el título de tu plantilla', this.state.toaststyle)
          if(!design) return toast.error('Falta configurar tu plantilla', this.state.toaststyle)
          if(!html) return toast.error('Falta configurar tu plantilla', this.state.toaststyle)
          nuevoregistro.json_design = design
          nuevoregistro.html_text = html
          nuevoregistro.bloques = []
          nuevoregistro.creador = {
              nombres: user.nombres,
              email: user.email
          }
          nuevoregistro.web = user.web
          const guardar = await this.newPlantillas(nuevoregistro)

        });
      };


      

    mostrarPlantilla(){
        const { guardando, guardado, guardandoPlantilla, emails, nuevoregistro, plantillas_email, listas, filtrousuario, tipousuario } = this.state

        if(guardado) return <Row className="justify-content-md-center mt-7">
        <Col md={6} className="text-center ">
        <img className="iconsmall mb-3" src="check.gif" />
        <h5>Creado exitosamente</h5>
        <p className="nomargin">Tu plantilla fue creada, ahora puedes utilizarla al crear mensajes de correo en el menú <b>Emails</b></p>
        <Link to="/templates-emails">Atrás</Link>
        </Col>
        </Row> 

        let options = []
        let plantillas = []

        listas.map(opcion => {
            options.push({ value: opcion._id, label: `${opcion.titulo} · ${opcion.subtipo}` })
        })
        plantillas_email.map(opcion => {
            plantillas.push({ value: opcion._id, label: opcion.titulo })
        })

        if(guardando){
            return <div>
                <Spinner animation="border" />
                <h3>Espere un momento...</h3>
            </div>
        }

        const lowercasedFilter = filtrousuario.toLowerCase();
        const listasFiltradas = emails.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        const filtradosportipo = tipousuario ? listasFiltradas.filter(user => user.tipo===tipousuario) : listasFiltradas

        return <Card style={{ width: '100%' }}>
        <Card.Body>
         
          <Row>

          <Col xs={12}><h4><i className="fas fa-ellipsis-h"></i> Configuración general</h4></Col>

            <Col md={3}>
            <label className="form-control-label">Título</label>
            <input name="titulo" className="form-control mb-4" value={nuevoregistro.titulo} onChange={this.handleChangeRegistro} />
            </Col>

            <Col md={3}>
            <label className="form-control-label">Click para guardar</label>
            { guardandoPlantilla ? <Spinner animation="border" /> : <button className="btn btn-success" onClick={()=>this.exportTemplate()}>GUARDAR PLANTILLA</button> }
            
            </Col>

            <Col xs={12}><h4><i className="far fa-object-group"></i> Diseño</h4><hr className="hr" /></Col>

            <Col md={12}>
            <EmailEditor ref={this.referencia} />
            </Col>

          </Row>

        </Card.Body>
      </Card>
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>
       <ToastContainer />   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2"> Nueva plantilla </h1>
      </div>

      {this.mostrarPlantilla()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(PlantillaEmailNew);