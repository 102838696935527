import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { ToastContainer, toast } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";
import Select from 'react-select'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import 'react-toastify/dist/ReactToastify.css'
import Accordion from 'react-bootstrap/Accordion'
import { Link } from 'react-router-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Toast from 'react-bootstrap/Toast'
import Moment from 'react-moment';
import 'moment/locale/es';

class CustomerDetails extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            customer: false,
            matrices: [],
            loadingUsuarios: true,
            filtroevaluaciones: '',
            loadingDetails: true,
            showcreacion: false,
            loadingcargos: true,
            cargos: [],
            areas: [],
            loadingareas: true,
            loadingmatrices: true,
            evaluaciones: [],
            guardandocambios: false,
            usuarios: [],
            evaluacionprevia: false,
            idcustomer: this.props.match.params.id,
            toaststyle: { position: toast.POSITION.BOTTOM_RIGHT },
            loadingDetailses: true,
            cargandoinfoinsignia: false,
            informacioninsignia: false,
            actividades: [],
            notas: [],
            emails: [],
            vidaprospecto: [],
            filtrotipo: '',
            filtrointencion: '',
            filtrointereses: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
        this.handleChangeUser = this.handleChangeUser.bind(this)
        this.handleChangeCargo = this.handleChangeCargo.bind(this)
    }

    formatoNumero(num){
        return `$${Intl.NumberFormat(['ban','id']).format(parseFloat(num))}`
    }

    sincorreos(){
        return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <img className="iconsmall mb-3" src="mailing.png" />
        <h5>No hay resultados</h5>
        <p>No hay emails creados para este contacto</p>
        <button className="btn btn-sm btn-primary">ENVIAR NUEVO EMAIL</button>
            </Col>
        </Row> 
    }

    mostrarEmails(){
        const { customer } = this.state

        if(!customer.emails_sends) return this.sincorreos() 

        if(customer.emails_sends.length < 1) return this.sincorreos()

        return <div>

            {
                customer.emails_sends.map((pedido,ipe) => {

                    return <Card>
                    <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} x={`acc${ipe+1}`}>
                      <p className="nav-link mb-0" style={{ float: 'left' }}>{pedido.subject} ·  
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(pedido.date_created, 'es-ES')}</Tooltip>}>
                      <b><Moment locale="ES" fromNow>{pedido.date_created}</Moment></b>
                      </OverlayTrigger></p>
                      <div style={{ clear: 'both' }}></div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`acc${ipe+1}`}>
                      <Card.Body>
                      <div dangerouslySetInnerHTML={{__html: pedido.html }} /> 
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                })
            }
        </div>
    }

    fechaATexto(fecha,lang){
        const date = new Date(fecha)
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return date.toLocaleDateString(lang ? lang : 'es-ES', options)
    }
    mostrarPedidos(){
        const { customer } = this.state

        if(customer.pedidos.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <img className="iconsmall mb-3" src="orders.png" />
        <h5>No hay resultados</h5>
        <p>No hay pedidos para este contacto</p>
            </Col>
        </Row> 

        const total_revenue = customer.pedidos.reduce((anterior,actual) => {
            return parseFloat(anterior) + parseFloat(actual.total)
        },0)

        return <div>
<h6>{customer.pedidos.length} transacciones encontradas</h6>
<p>Total compras <b>{this.formatoNumero(total_revenue)}</b></p>
<Accordion>
          
            {
                customer.pedidos.map((pedido,ipe) => {
                    const creacion = new Date()
                    return <Card>
                    <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey={ipe+1}>
                      <p className="nav-link mb-0" style={{ float: 'left' }}>Orden #{pedido.pedido} · 
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(pedido.date_created.date, 'es-ES')}</Tooltip>}>
                      <b><Moment locale="ES" fromNow>{pedido.date_created.date}</Moment></b>
                      </OverlayTrigger></p>
                      <p className="nav-link mb-0" style={{ float: 'right' }}><b>{this.formatoNumero(pedido.total)}</b></p>
                      <div style={{ clear: 'both' }}></div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ipe+1}>
                      <Card.Body>
                      <Row>
                          <Col md={3}>
                              <label className="form-control-label">Nombre</label>
                              <p>{pedido.billing.name} {pedido.billing.lastname}</p>
                          </Col>

                          <Col md={3}>
                              <label className="form-control-label">Teléfono</label>
                              <p>{pedido.billing.phone}</p>
                          </Col>

                          <Col md={6}>
                              <label className="form-control-label">Dirección</label>
                              <p>{pedido.billing.address1}</p>
                          </Col>

                          <Col md={3}>
                              <label className="form-control-label">Método de pago</label>
                              <p>{pedido.metodo_pago}</p>
                          </Col>

                          <Col md={3}>
                              <label className="form-control-label">Método de envío</label>
                              <p>{pedido.metodo_envio}</p>
                          </Col>

                          <Col md={3}>
                              <label className="form-control-label">Total descuento</label>
                              <p>{this.formatoNumero(pedido.discount_total)}</p>
                          </Col>

                          <Col md={12}>
                              <h6>Productos</h6>

                            {this.tablaProductos(pedido.produtos ? pedido.produtos : [])}

                          </Col>
                      </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                })
            }
            </Accordion>
        </div>


    }

    mostrarNotas(){
        const { notas } = this.state

        if(notas.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <h5>No hay resultados</h5>
        <p>No hay notas creadas para este contacto</p>
        <button className="btn btn-sm btn-primary">CREAR NOTA</button>
            </Col>
        </Row> 
    }

    mostrarIntereses(){
        const { customer, filtrointereses } = this.state

        if(customer.intereses.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <img className="iconsmall mb-3" src="data.png" />
        <h5>No hay resultados</h5>
        <p>No hay intereses relevantes para este contacto, los intereses son basados en las compras, detectamos e identificamos patrones de compra</p>
            </Col>
        </Row> 

        const datosfiltrados = filtrointereses ? customer.intereses.filter(info => info.tipo === filtrointereses) : customer.intereses

        return <div>

        <Row>
        <Col md={12}>
                <h5>Análisis basado en pedidos de este contacto</h5>
                <div className="form-group">
                <label className="form-control-label">Filtrar</label>
                <select className="form-control" name="filtrointereses" onChange={this.handleChange}>
                    <option value="">Todos</option>
                    <option value="producto">Productos</option>
                    <option value="categoria">Categorías</option>
                    <option value="etiqueta">Etiquetas</option>
                </select>
                </div>
                <hr />

                <Row>
                {datosfiltrados.map(interes => {

                    return <Col md={6} className="mb-4">
                        {this.mostrarInteresByTipo(interes)}
                        </Col>
                })}
                </Row>

            </Col>


        </Row>

        </div>
    }

    mostrarActividades(){
        const { customer, filtrointencion } = this.state

        if(customer.intenciones.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <img className="iconsmall mb-3" src="data.png" />
        <h5>No hay resultados</h5>
        <p>No hay intenciones de compra relevantes para este contacto</p>
            </Col>
        </Row> 

        const datosfiltrados = filtrointencion ? customer.intenciones.filter(info => info.tipo === filtrointencion) : customer.intenciones


        return <div>

        <Row>
        <Col md={12}>
                <h5>Intenciones de compra basadas en la navegación</h5>
                <div className="form-group">
                <label className="form-control-label">Filtrar</label>
                <select className="form-control" name="filtrointencion" onChange={this.handleChange}>
                    <option value="">Todos</option>
                    <option value="producto">Productos</option>
                    <option value="categoria">Categorías</option>
                    <option value="etiqueta">Etiquetas</option>
                </select>
                </div>
                <hr />

                <Row>
                {datosfiltrados.map(intencion => {

                    return <Col md={6} className="mb-4">
                        {this.mostrarIntencionesByTipo(intencion)}
                        </Col>
                })}
                </Row>

            </Col>


        </Row>

        </div>

    }

    mostrarInteresByTipo(registro){


        if(registro.tipo==='producto'){

            let tags = []
                let categorias = []

                if(registro.producto.tags){
                    if(Array.isArray(registro.producto.tags) !== false){
                        if(registro.producto.tags.length > 0){
                            registro.producto.tags.map(tag => {
                                tags.push(tag.name.toLowerCase())
                            })
                        }
                    }
                }

                if(registro.producto.categorias){
                    if(Array.isArray(registro.producto.categorias) !== false){
                        if(registro.producto.categorias.length > 0){
                            registro.producto.categorias.map(cat => {
                                categorias.push(cat.name.toLowerCase())
                            })
                        }
                    }
                }


            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-shopping-cart"></i> PRODUCTO</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>Comprado {registro.repeticion} veces</span>
              </OverlayTrigger>
                      </small>
            </Toast.Header>
            <Toast.Body>
                <Row>
                    <Col xs={5} style={{ textAlign: 'center' }}>
                    <img src={registro.producto.imagen} style={{ width: '100%' }} />
                    <p class="nomargin">{registro.producto.nombre}</p>
                    </Col>
                    <Col xs={7}>
                    <p className="d-block nomargin">Precio</p>
                    <h6 class="nomargin">{this.formatoNumero(registro.producto.price)}</h6>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Etiquetas</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{tags.join(', ')}</span>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Categorías</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{categorias.join(', ')}</span>
                    <hr style={{ margin: '5px 0px' }} />
                    <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
                    <p className="d-block nomargin"><i className="far fa-clock"></i> Última actualización</p>
                    <p style={{ fontSize:12 }} className="nomargin"><Moment locale="ES" fromNow>{registro.ultima_actualizacion}</Moment></p>
                    </div>
                    </Col>
                </Row>
            </Toast.Body>
          </Toast>
        } else if(registro.tipo === 'categoria'){

            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-tags"></i> CATEGORIA</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>{registro.repeticion} productos comprados</span>
              </OverlayTrigger>
                  </small>
            </Toast.Header>
            <Toast.Body>
              <p className="d-block nomargin">Nombre</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.name : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <p className="d-block nomargin">Slug</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.slug : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
              <p className="d-block nomargin"><i className="far fa-clock"></i> Última actualización</p>
                    <p style={{ fontSize:12 }} className="nomargin"><Moment locale="ES" fromNow>{registro.ultima_actualizacion}</Moment></p>
              </div>
              <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
            </Toast.Body>
          </Toast>
        } else if(registro.tipo === 'etiqueta'){

            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-tags"></i> ETIQUETA</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>{registro.repeticion} productos comprados</span>
              </OverlayTrigger>
                  </small>
            </Toast.Header>
            <Toast.Body>
              <p className="d-block nomargin">Nombre</p>
              <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.name : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <p className="d-block nomargin">Slug</p>
              <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.slug : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
              <p className="d-block nomargin"><i className="far fa-clock"></i> Última actualización</p>
              <p style={{ fontSize:12 }} className="nomargin"><Moment locale="ES" fromNow>{registro.ultima_actualizacion}</Moment></p>
              </div>
              <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
            </Toast.Body>
          </Toast>
        }

        return <div>

            
            
        </div>
    }

    mostrarIntencionesByTipo(registro){


        if(registro.tipo==='producto'){

            let tags = []
                let categorias = []

                if(registro.producto.tags){
                    if(Array.isArray(registro.producto.tags) !== false){
                        if(registro.producto.tags.length > 0){
                            registro.producto.tags.map(tag => {
                                tags.push(tag.name.toLowerCase())
                            })
                        }
                    }
                }

                if(registro.producto.categorias){
                    if(Array.isArray(registro.producto.categorias) !== false){
                        if(registro.producto.categorias.length > 0){
                            registro.producto.categorias.map(cat => {
                                categorias.push(cat.name.toLowerCase())
                            })
                        }
                    }
                }


            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-shopping-cart"></i> PRODUCTO</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>Visto {registro.repeticion} veces</span>
              </OverlayTrigger>
                      </small>
            </Toast.Header>
            <Toast.Body>
                <Row>
                    <Col xs={5} style={{ textAlign: 'center' }}>
                    <img src={registro.producto.imagenes} style={{ width: '100%' }} />
                    <p class="nomargin">{registro.producto.nombre}</p>
                    </Col>
                    <Col xs={7}>
                    <p className="d-block nomargin">Precio</p>
                    <h6 class="nomargin">{this.formatoNumero(registro.producto.precio)}</h6>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Etiquetas</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{tags.join(', ')}</span>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Categorías</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{categorias.join(', ')}</span>
                    <hr style={{ margin: '5px 0px' }} />
                    <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
                    <p className="d-block nomargin"><i className="far fa-clock"></i> Últimos datos</p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Desde</b> <Moment locale="ES" fromNow>{registro.desde}</Moment></p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Hasta</b> <Moment locale="ES" fromNow>{registro.hasta}</Moment></p>
                    </div>
                    </Col>
                </Row>
            </Toast.Body>
          </Toast>
        } else if(registro.tipo === 'categoria'){

            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-tags"></i> CATEGORIA</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>Visto {registro.repeticion} veces</span>
              </OverlayTrigger>
                  </small>
            </Toast.Header>
            <Toast.Body>
              <p className="d-block nomargin">Nombre</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.name : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <p className="d-block nomargin">Slug</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.slug : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
                    <p className="d-block nomargin"><i className="far fa-clock"></i> Últimos datos</p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Desde</b> <Moment locale="ES" fromNow>{registro.desde}</Moment></p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Hasta</b> <Moment locale="ES" fromNow>{registro.hasta}</Moment></p>
                    </div>
              <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
            </Toast.Body>
          </Toast>
        } else if(registro.tipo === 'etiqueta'){

            return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-tags"></i> ETIQUETA</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">En toda la historia</Tooltip>}>
              <span>Visto {registro.repeticion} veces</span>
              </OverlayTrigger>
                  </small>
            </Toast.Header>
            <Toast.Body>
              <p className="d-block nomargin">Nombre</p>
              <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.name : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <p className="d-block nomargin">Slug</p>
              <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.slug : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <div style={{ background: '#ecebeb', padding: '8px 11px', borderRadius: 8 }}>
                    <p className="d-block nomargin"><i className="far fa-clock"></i> Últimos datos</p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Desde</b> <Moment locale="ES" fromNow>{registro.desde}</Moment></p>
                    <p style={{ fontSize:12 }} className="nomargin"><b>Hasta</b> <Moment locale="ES" fromNow>{registro.hasta}</Moment></p>
                    </div>
              <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
            </Toast.Body>
          </Toast>
        }

        return <div>

            
            
        </div>
    }

    mostrarCarrito(){
        const { customer } = this.state

        if(!customer.carrito) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
            <img className="iconsmall mb-3" src="carrito.png" />
        <h5>No hay carrito activo</h5>
        <p>No hay carritos activos actualmente de este contacto</p>
            </Col>
        </Row> 

        return <div>
            <h4>Carrito activo</h4>
            <p><b>Última actualización: </b><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(customer.carrito.fecha_actualizado)}</Tooltip>}>
            <Moment locale="ES" fromNow>{customer.carrito.fecha_actualizado}</Moment>

</OverlayTrigger> </p>
<Card>
    <Card.Body>
        <Row>
        <Col md={2}>
        Imagen
    </Col>
    <Col md={6}>
        Producto
    </Col>
    <Col md={2}>
        Cantidad
    </Col>
    <Col md={2}>
        Precio
    </Col>
    <Col xs={12}>
    <hr />
    </Col>
        </Row>
    { customer.carrito.carrito.map((producto,ipro) => {

return <Row>
    <Col md={2}>
        <img src={producto.imagenes} style={{ width: '100%' }} />
    </Col>
    <Col md={6}>
        <h6 class="nomargin">{producto.nombre}</h6>
    </Col>
    <Col md={2}>
        {producto.cantidad}
    </Col>
    <Col md={2}>

    </Col>
    <Col xs={12}>
    <hr />
    </Col>
</Row>

}) }
    </Card.Body>
</Card>
        

        </div>
    }

    tablaProductos(productos){

        return <Card>
        <Card.Body>
            <Row>
            <Col xs={2}>
            Imagen
        </Col>
        <Col xs={6}>
            Producto
        </Col>
        <Col xs={2}>
            Cantidad
        </Col>
        <Col xs={2}>
            Total
        </Col>
        <Col xs={12}>
        <hr />
        </Col>
            </Row>
        { productos.map((producto,ipro) => {
    
    return <Row key={`pro-${ipro}`}>
        <Col xs={2}>
            <img src={producto.imagen} style={{ width: '100%' }} />
        </Col>
        <Col xs={6}>
            <h6 class="nomargin">{producto.name}</h6>
        </Col>
        <Col xs={2}>
            {producto.quantity}
        </Col>
        <Col xs={2}>
            {this.formatoNumero(producto.total)}
        </Col>
        <Col xs={12}>
        <hr />
        </Col>
    </Row>
    
    }) }
        </Card.Body>
    </Card>
    }

    mostrarNavegacion(){
        const { customer, filtrotipo } = this.state

        if(customer.navegacion.length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center centerv">
        <h5>No hay resultados</h5>
        <p>No hay registros creados para este contacto</p>
        
            </Col>
        </Row> 

        const datos = customer.navegacion
        const datosfiltrados = filtrotipo ? datos.filter(dato => dato.subtipo === filtrotipo) : datos

        return <Row>
            <Col md={11}>
            <h6>{customer.navegacion.length} registros</h6>

            <div className="form-group">
            <label className="form-control-label">Filtrar</label>
            <select className="form-control" name="filtrotipo" onChange={this.handleChange}>
                <option value="">Todos</option>
                <option value="producto">Productos</option>
                <option value="categoria">Categorías</option>
                <option value="etiqueta">Etiquetas</option>
            </select>
            </div>
            </Col>

            {
                datosfiltrados.map(registro => {
                    return <Col md={6} className="mb-4">
                        {this.showByType(registro)}
                    </Col>
                })
            }
        </Row>
    }

    showByType(registro){
        if(registro.tipo === 'navegacion'){

            

            if(registro.subtipo === 'producto') {
                
                let tags = []
                let categorias = []

                if(registro.datosproducto.tags){
                    if(Array.isArray(registro.datosproducto.tags) !== false){
                        if(registro.datosproducto.tags.length > 0){
                            registro.datosproducto.tags.map(tag => {
                                tags.push(tag.name.toLowerCase())
                            })
                        }
                    }
                }

                if(registro.datosproducto.categorias){
                    if(Array.isArray(registro.datosproducto.categorias) !== false){
                        if(registro.datosproducto.categorias.length > 0){
                            registro.datosproducto.categorias.map(cat => {
                                categorias.push(cat.name.toLowerCase())
                            })
                        }
                    }
                }
                
                return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-shopping-cart"></i> PRODUCTO</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(registro.fecha_creacion, 'es-ES')}</Tooltip>}>
              <Moment locale="ES" fromNow>{registro.fecha_creacion}</Moment>
              </OverlayTrigger>
                      </small>
            </Toast.Header>
            <Toast.Body>
                <Row>
                    <Col xs={5} style={{ textAlign: 'center' }}>
                    <img src={registro.datosproducto.imagenes} style={{ width: '100%' }} />
                    <p class="nomargin">{registro.datosproducto.nombre}</p>
                    </Col>
                    <Col xs={7}>
                    <p className="d-block nomargin">Precio</p>
                    <h6 class="nomargin">{this.formatoNumero(registro.datosproducto.precio)}</h6>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Etiquetas</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{tags.join(', ')}</span>
                    <hr style={{ margin: '5px 0px' }} />
                    <p className="d-block nomargin">Categorías</p>
                    <span style={{ fontWeight: 'bold', fontSize: 11 }}>{categorias.join(', ')}</span>
                    </Col>
                </Row>
            </Toast.Body>
          </Toast>
          } else if(registro.subtipo === 'etiqueta') return <Toast>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
            <strong className="mr-auto"><i className="fas fa-tags"></i> ETIQUETA</strong>
            <small>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(registro.fecha_creacion, 'es-ES')}</Tooltip>}>
            <Moment locale="ES" fromNow>{registro.fecha_creacion}</Moment>
              </OverlayTrigger>
                </small>
          </Toast.Header>
          <Toast.Body>
            <p className="d-block nomargin">Nombre</p>
            <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.name : 'Desconocido'}</h6>
            <hr style={{ margin: '5px 0px' }} />
            <p className="d-block nomargin">Slug</p>
            <h6 class="nomargin">{registro.etiqueta ? registro.etiqueta.slug : 'Desconocido'}</h6>
            <hr style={{ margin: '5px 0px' }} />
            <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
          </Toast.Body>
        </Toast>

            if(registro.subtipo === 'categoria') return <Toast>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto"><i className="fas fa-bookmark"></i> CATEGORÍA</strong>
              <small>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.fechaATexto(registro.fecha_creacion, 'es-ES')}</Tooltip>}>
              <Moment locale="ES" fromNow>{registro.fecha_creacion}</Moment>
              </OverlayTrigger>
              </small>
            </Toast.Header>
            <Toast.Body>
              <p className="d-block nomargin">Nombre</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.name : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <p className="d-block nomargin">Slug</p>
              <h6 class="nomargin">{registro.categoria ? registro.categoria.slug : 'Desconocido'}</h6>
              <hr style={{ margin: '5px 0px' }} />
              <a href={registro.pagina} style={{ fontSize: 10 }} target="_blank">{registro.pagina}</a>
            </Toast.Body>
          </Toast>

        }

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeUser(e){
        const { evaluacion, usuarios } = this.state
        const listausuarios = []
        e.map(persona => {
            const buscar = usuarios.findIndex(user => user._id['$oid'] === persona.value)
            if(usuarios[buscar]){
                listausuarios.push(usuarios[buscar])
            }
        })
        evaluacion.listausuarios = listausuarios
        console.log(evaluacion)
        return this.setState({ evaluacion: evaluacion })
    }
    
    handleChangeCargo(e){
        const { evaluacion, cargos, matrices, areas } = this.state
        const buscar = cargos.findIndex(puesto => puesto._id['$oid'] === e.value)
        if(cargos[buscar]){
            evaluacion.cargo = cargos[buscar]
            if(cargos[buscar].idmatriz){
                const buscarmatriz = matrices.findIndex(mat => mat._id['$oid'] === cargos[buscar].idmatriz)
                if(matrices[buscarmatriz]){
                    evaluacion.matriz = matrices[buscarmatriz]
                }
            }
            if(cargos[buscar].areaasignada){
                const buscararea = areas.findIndex(area => area._id['$oid'] === cargos[buscar].areaasignada)
                if(areas[buscararea]){
                    evaluacion.cargo.area = areas[buscararea]
                }
            }
        }

        console.log(evaluacion)
        return this.setState({ evaluacion: evaluacion })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { cargo } = this.state
        cargo[name] = value
        console.log(cargo)
        return this.setState({ cargo: cargo })
    }

    componentDidMount(){
        const { user, idcustomer } = this.state
        this.getCustomerDetail(idcustomer)
        
    }


    async actualizarData(id){
        const  { evaluacion, cargos, areas, matrices, user } = this.state
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_refreshUserData?id=${id}`)
        .then(res => res.json())
        .then(async res => {
            if(!res) return window.location.replace('/login')
            console.log(res)
            
            this.setState({ user: res })
        })
        .catch(error => {
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getEvaluaciones(condicion){

        this.setState({ loadingDetailses: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMultiplesEvaluaciones`,{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingDetailses: false, evaluaciones: res })
        })
        .catch(error => {
            this.setState({ loadingDetailses: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }


    getDetalleInsignia(id){
        this.setState({ cargandoinfoinsignia: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_GetInsigniaById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            return this.setState({ cargandoinfoinsignia: false, informacioninsignia: res })
        })
        .catch(error => {
            this.setState({ cargandoinfoinsignia: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    getCustomerDetail(id){
        const { user } = this.state
        this.setState({ loadingDetails: true })
        return fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/tremus-suvjx/service/crm/incoming_webhook/web_CustomerById?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const usuario = JSON.parse(res)
            console.log(usuario)
            return this.setState({ loadingDetails: false, customer: usuario })
        })
        .catch(error => {
            this.setState({ loadingDetails: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    guardarCrear(status){
        const { evaluacion, user, evaluacionoriginal } = this.state
        
        evaluacion.propietario = user.propietario
        evaluacion.creado = this.formatDate(new Date())
        
        let llenado = 0
        let correctas = 0
        let cantidadhabilidades = 0
        let totalparapuntaje = 0
        let promediodeseado = 0
        evaluacion.matriz.esquema.niveles.map(nivel => {
            if(!nivel.niveles) return false
            nivel.niveles.map(level=>{
                if(!level.niveles) return false
                level.niveles.map(le=>{
                    if(!le.habilidades) return false
                    le.habilidades.map(hab=>{
                        cantidadhabilidades++
                        if(hab.logrado) llenado++
                        const esperado = hab.esperado ? parseInt(hab.esperado) : 0
                        const logrado = hab.logrado ? parseInt(hab.logrado) : 0
                        totalparapuntaje = totalparapuntaje + logrado
                        promediodeseado = promediodeseado + esperado
                        if(logrado>=esperado) correctas++
                    })
                })
            })
        })
        const deseado = Math.round(promediodeseado / cantidadhabilidades)
        const puntaje = (totalparapuntaje / cantidadhabilidades).toFixed(2)

        let categoria = 'E'

        if(puntaje > (deseado*0.5).toFixed(2) && puntaje <= deseado ) categoria = 'D'
        if(puntaje > deseado && puntaje < (deseado*1.3).toFixed(2) ) categoria = 'C'
        if(puntaje > (deseado*1.3).toFixed(2) && puntaje < (deseado*1.6).toFixed(2) ) categoria = 'B'
        if(puntaje > (deseado*1.6).toFixed(2) ) categoria = 'A'



        evaluacion.porcentajellenado = Math.round(llenado/cantidadhabilidades*100).toString()
        evaluacion.porcentajecorrectas = Math.round(correctas/cantidadhabilidades*100).toString()
        evaluacion.resultadopromedio = (totalparapuntaje / cantidadhabilidades).toFixed(2).toString()
        evaluacion.promediodeseado = (promediodeseado / cantidadhabilidades).toFixed(2).toString()
        evaluacion.categoria = categoria
        evaluacion.status = status.toString()
        evaluacion.fechaedicion = this.formatDate(new Date())

        let urlenviar = ''
        let bodyenviar = {evaluacion: evaluacion, evaluacionoriginal: evaluacionoriginal}
        if(status==='0'){
            urlenviar = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_EditarEvaluacion'
            bodyenviar = evaluacion
        } else if(status==='1'){
            evaluacion.fechaconfirmacion = this.formatDate(new Date())
            urlenviar = 'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_ConfirmarEvaluacion'
        } else {
            return false
        }

        //return console.log(bodyenviar)
        this.setState({ guardandocambios: true })
        return fetch(urlenviar,{
            method: 'POST',
            body: JSON.stringify(bodyenviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = evaluacion._id ? 'Evaluación modificada exitosamente' : 'Evaluación ingresada exitosamente'
            toast.success(texto, this.state.toaststyle)
            this.getCustomerDetail(this.state.idevaluacion)
            return this.setState({ guardandocambios: false })
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Confirmada'
                    break;
            default:
                return 'No confirmada'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, cargos, cargo, areas, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { cargo._id ? <h3>Editar evaluación {cargo.nombre ? cargo.nombre : 'usuario'} </h3> : <h3>Crear evaluación</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label d-block">Nombre</label>
    <input name="nombre" className="form-control" value={cargo.nombre ? cargo.nombre : ''} onChange={this.handleChangeRol} />
</Col>

<Col md={6} >
    <label className="form-control-label d-block">Descripción</label>
    <input name="descripcion" className="form-control" value={cargo.descripcion} onChange={this.handleChangeRol} />
</Col>

<Col md={3} >
    <label className="form-control-label d-block">Cargo</label>
    <select name="areaasignada" className="form-control" value={cargo.areaasignada ? cargo.areaasignada : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            cargos.length > 0 ? cargos.map(puesto => {
                return <option value={puesto._id['$oid']}> {puesto.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label d-block">Click para { cargo._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ cargo._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0 onclick" onClick={()=>this.setState({ cargo: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    showCustomer(){
        const { loadingDetails, customer } = this.state

        if(loadingDetails){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

       if(!customer) return false

        return <div>

<Row>

<Col md={3} >

<Card>
    <Card.Body>
        <Link to="/customers"><span style={{ fontSize: 13 }}>Atrás</span></Link>
        <h4>{customer.data.user_nicename}</h4>

        <div className="form-group">
        <label className="form-control-label">Teléfono</label>
        <input className="form-control" defaultValue={customer.data.phone} />
        </div>

        <div className="form-group">
        <label className="form-control-label">Correo electrónico</label>
        <input className="form-control" defaultValue={customer.data.user_email} />
        </div>

        <div className="form-group">
        <label className="form-control-label">Dirección</label>
        <input className="form-control" defaultValue={customer.data.address} />
        </div>
        
        <div className="form-group">
        <label className="form-control-label">Región</label>
        <input className="form-control" defaultValue={customer.data.region} />
        </div>

        <div className="form-group">
        <label className="form-control-label">Comuna</label>
        <input className="form-control" defaultValue={customer.data.comuna} />
        </div>

        <div className="form-group">
        <label className="form-control-label">Rut</label>
        <input className="form-control" defaultValue={customer.data.rut} />
        </div>
    </Card.Body>
</Card>

</Col>


<Col md={9} >

<Tabs defaultActiveKey="intencion" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="intencion" title="Intención de compra">
  {this.mostrarActividades()}
  </Tab>
  <Tab eventKey="vida" title="Navegación">
  {this.mostrarNavegacion()}
  </Tab>
  <Tab eventKey="intereses" title="Intereses">
  {this.mostrarIntereses()}
  </Tab>
  <Tab eventKey="pedidos" title="Pedidos">
  {this.mostrarPedidos()}
  </Tab>
  <Tab eventKey="emails" title="Correos electrónicos">
  {this.mostrarEmails()}
  </Tab>
  
  <Tab eventKey="carrito" title="Carrito abandonado">
  {this.mostrarCarrito()}
  </Tab>
</Tabs>

</Col>




</Row>




        </div>
    }



    mostrarAccionesSoloParaEvaluador(evaluacion){
        const { guardandocambios, user, evaluacionoriginal } = this.state

        if(user.tipo === 'admin' || user.tipo === 'evaluator'){
            if(evaluacion.status==='1'){
                return <div><h6>Esta evaluación ya no se puede editar</h6></div>
            }
            return <div>

                {
                    evaluacion.status==='0' && evaluacionoriginal ? <button className="btn link" onClick={()=>this.setState({ evaluacion: evaluacionoriginal})} >REINICIAR A EVALUACIÓN ORIGINAL</button> : false
                }

                {
                    guardandocambios ? <div>
                    <Spinner animation="border" />
                    <h3>Espera un momento...</h3>
                </div> : <div>
                <button className="btn btn-outline-primary mr-3" onClick={()=>this.guardarCrear('0')} >GUARDAR CAMBIOS</button>
                <button className="btn btn-success" onClick={()=>this.guardarCrear('1')} >GUARDAR Y CONFIRMAR EVALUACIÓN</button>
                {this.accionesEvaluacionInsignia(evaluacion.tipo)}
                </div>
                }
            </div>
        }
        
        return false
    }

    accionesEvaluacionInsignia(tipo){
        const { aprobandoinsignia } = this.state
        if(tipo === 'insignia') return <div>
        
        { aprobandoinsignia ? <div>
            <Spinner animation="border" />
            <h3>Cargando...</h3>
        </div> : <button className="btn btn-outline-warning mr-3" onClick={()=>this.aprobarInsignia()} >APROBAR INSIGNIA</button> 
        }

        </div>
    }

    aprobarInsignia(){
        const { evaluacion, informacioninsignia, user } = this.state
        
        const enviar = {
            idinsignia: evaluacion.idinsignia,
            informacioninsignia: informacioninsignia,
            fecha_creacion: this.formatDate(new Date()),
            idusuario: evaluacion.usuarioevaluado.id,
            evaluacion: evaluacion._id['$oid'],
            evaluador: user
        }

        this.setState({ aprobandoinsignia: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_AprobarInsignia`,{
            method: 'POST',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({ aprobandoinsignia: false })
            return toast.success("Insignia aprobada exitosamente", this.state.toaststyle)

        })
        .catch(error => {
            this.setState({ aprobandoinsignia: false })
            return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    seleccionarHabilidad(valor, inivel, levi, leveli, ihab){

        const { evaluacion } = this.state

        if(evaluacion.status==='1') return toast.warn('Esta evaluación ya fue aprobada y no se puede editar', this.state.toaststyle)

        if(evaluacion.matriz.esquema.niveles[inivel]){
            console.log(evaluacion.matriz.esquema.niveles[inivel].nombre)
            if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi]){
                console.log(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].nombre)
                if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli]){
                    console.log(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].nombre)
                    if(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab]){
                        evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab].logrado = valor.toString()
                        console.log(evaluacion.matriz.esquema.niveles[inivel].niveles[levi].niveles[leveli].habilidades[ihab])
                        return this.setState({ evaluacion: evaluacion })
                    }

                }
            }

        }
    }

    ifAreaExist(idarea){
        const { areas } = this.state

        if(areas.length > 0){
            const buscar = areas.findIndex(ma => ma._id['$oid'] === idarea)
            if(areas[buscar]){
                return areas[buscar].nombre
            }
        }

        return 'No encontrado'
    }

    ifMatrixExist(idmatriz){
        const { matrices } = this.state

        if(matrices.length > 0){
            const buscar = matrices.findIndex(ma => ma._id['$oid'] === idmatriz)
            if(matrices[buscar]){
                return matrices[buscar].nombre
            }
        }

        return 'No encontrado'
    }
    
    render(){

        const {miusuario} = this.props
        const { errorLogin } = this.state
        return(
            <div className="fluid">
       <Header/>   
       <ToastContainer />
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Detalles de contacto </h1>
      </div>

      {this.showCustomer()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(CustomerDetails);