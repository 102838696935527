import React, { Component} from 'react'
import { connect } from 'react-redux'
import { userService } from '../../services/user.service'
import { Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
            loadinguser: true,
            loadinginsignias: true,
            insignias: []
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }


    componentDidMount(){
      const { user } = this.state
      console.log(user)
      // this.actualizarData(user._id['$oid'])
      this.getInsignias({ idusuario: user._id['$oid']})
    }

    async actualizarData(id){
      this.setState({ loadinguser: true })
      return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_refreshUserData?id=${id}`)
      .then(res => res.json())
      .then(async res => {
          if(!res) return window.location.replace('/login')
          console.log(res)
          
          localStorage.setItem( "wesyncro_user", JSON.stringify(res) )
          this.setState({ user: res, loadinguser: false })
      })
      .catch(error => {
          this.setState({ loadinguser: false })
          return toast.error("Error al consultar la información, intente recargar la página", this.state.toaststyle)
      })

  }

    handleSubmit() {

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (!(username && password )) {
            return;
        }

        this.setState({ loading: true });
        userService.login(username, password )
            .then(
                user => {
                    if(!user){
                        this.setState({ errorLogin: "Credenciales incorrectas", loading: false })
                    } else {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    }
                    
                },
                error => this.setState({ error, loading: false })
            );
    }

    misDatos(){
      const { user } = this.state

      /*

        <Col md={3}>
        <Card className="text-white cardblue equalheight">
  <Card.Body>
    <img src="user-icon.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Nivel de usuario</p>
    <Card.Title>{user.matriz ? `${user.matriz.nombre}` : false } </Card.Title>
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="equalheight">
  <Card.Body>
    <img src="cargo.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Cargo de trabajo</p>
    {
      user.detallescargo ? <div>
        <Card.Title className="nomargin">{user.detallescargo.nombre ? user.detallescargo.nombre : 'Desconocido' } </Card.Title>
        <p className="nomargin" style={{ fontWeight: 'lighter' }} >{user.detallescargo.descripcion}</p>
      </div> : <p>No tienes un cargo asignado dentro de la empresa</p>
    }
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="cardorange equalheight">
  <Card.Body>
    <img src="area.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Evaluaciones</p>
    <Card.Title>{user.evaluaciones ? user.evaluaciones.length : false } </Card.Title>
    <Link to="/evaluaciones" ><button className="btn btn-sm btn-outline-light" >DETALLES</button></Link>
    
  </Card.Body>
</Card>
        </Col>

        <Col md={3}>
        <Card className="equalheight">
  <Card.Body>
    <img src="areatrabajo.png" style={{ width: 70 }} />
    <p className="nomargin" style={{ fontWeight: 'lighter' }} >Área de trabajo</p>
    {
      user.detallesarea ? <div>
        <Card.Title className="nomargin">{user.detallesarea.nombre ? user.detallesarea.nombre : 'Desconocido' } </Card.Title>
        <p className="nomargin" style={{ fontWeight: 'lighter' }} >{user.detallesarea.descripcion}</p>
      </div> : <p>No tienes un cargo asignado dentro de la empresa</p>
    }
    
  </Card.Body>
</Card>
        </Col>


      */
      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Estado del usuario</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{this.ifactive(user.status) }</p>
          </Card.Body>
        </Card>
        </Col>


        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Email</p>
            <p className="nomargin" style={{ fontSize: 12 }} >{user.email }</p>
          </Card.Body>
        </Card>
        </Col>

      </Row>

    }

    getInsignias(condicion){

      this.setState({ loadinginsignias: true })
      return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getInsigniasAprobadas`,{
          method: 'POST',
          body: JSON.stringify(condicion),
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(res => res.json())
      .then(res => {
          console.log(res)
          this.setState({ loadinginsignias: false, insignias: res })
      })
      .catch(error => {
          this.setState({ loadinginsignias: false })
          return toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
      })

  }

    mostrarInsignias(){
      const { insignias, loadinginsignias } = this.state

      if(loadinginsignias){
        return <div>
            <Spinner animation="border" />
            <h3>Buscando tus insignias...</h3>
        </div>
        }

        if(insignias.length < 1) return <h3>No tienes insignias </h3>

        return <Row>
          {
            insignias.map((insig,iinsig) => {
              if(!insig.informacioninsignia) return false
              return <Col sey={`ing${iinsig}`} xs={4}>
                <Card><Card.Body>
                  <img src="insignia.jpg" className="iconsystem" />
                  <h3 className="mb-0">{insig.informacioninsignia.nombre} <small className="text-success"><i className="far fa-check-circle"></i></small></h3>
                  <p className="mb-0">{insig.informacioninsignia.descripcion}</p>
                  </Card.Body></Card>
              </Col>
            })
          }
        </Row>

    }

    ifactive(status){
      switch(status){
        case '1':
          return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
      }
    }
    
    render(){

        const {miusuario} = this.props
        const { user } = this.state
        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Inicio</h1>
        
      </div>

      <h3 style={{ fontWeight:100}}>Bienvenido {user ?  <b>{user.nombres}</b> : false } </h3>

      {
        this.misDatos()
      }
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Home);