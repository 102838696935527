const control = {}

control.fechaATexto = (fecha, lang) => {
    const date = new Date(fecha)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString(lang ? lang : 'es-ES', options)
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}


module.exports = control