import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap'

class Nav extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            user: JSON.parse(localStorage.getItem('wesyncro_user')),
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    procesarOpciones(){
      const { user } = this.state

      let opciones = []
      if(user.matriz){
        if(user.matriz.esquema){
          if(Array.isArray(user.matriz.esquema)){
            if(user.matriz.esquema.length > 0){
              user.matriz.esquema.map(valor => {
                opciones.push(valor)
              })
            }
          }
        }
      }




      return opciones.map(elemento => {
        // <img style={{ width: 30 }} src="evaluaciones.jpg" />

        if(elemento.slug === 'habilidades') return <Accordion>
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"><i className={this.iconbytype(elemento.slug)}></i> Habilidades <i className="flecha fas fa-arrow-circle-right"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to={elemento.slug} className="nav-link"><i className={this.iconbytype(elemento.slug)}></i> Matrices </Link>
        <Link to="/biblioteca-habilidades" className="nav-link"><i className={this.iconbytype(elemento.slug)}></i> Biblioteca </Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

        return <li className="nav-item">
        <Link to={elemento.slug} className="nav-link"><i className="fas fa-users"></i> Contactos</Link>
      </li>
      })
    }

    iconbytype(slug){
      switch(slug){
        case'evaluaciones':
          return "fas fa-users-cog";
        case'usuarios':
          return "fas fa-users";
          case'areas':
          return "fas fa-users";
        case'roles':
          return "fas fa-users";
        case'cargos':
          return "fas fa-users";
        case'matrices':
          return "fas fa-user-shield";
        default:
          break;
      }
    }

    render(){

        return(
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/" className="nav-link"><i className="fas fa-home"></i> Inicio</Link>
          </li>
          <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Contactos <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/customers" className="nav-link">Contactos </Link>
        <Link to="/emails" className="nav-link">Emails </Link>
        <Link to="/lists" className="nav-link">Listas </Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

  <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Marketing <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/flows" className="nav-link">Flujos </Link>
        <Link to="/templates-emails" className="nav-link">Plantillas emails </Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>

  <Accordion defaultActiveKey="1">
          <Card>
      <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey="1">
        <p className="nav-link mb-0"> Configuración <i className="flecha fas fa-arrow-circle-down"></i></p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body style={{ padding: '8px 0px', fontSize: 12, background: '#e0e0e0' }}>
        <Link to="/settings" className="nav-link">Ajustes generales</Link>
        <Link to="/custom-fields" className="nav-link">Campos personalizados</Link>
        </Card.Body>
      </Accordion.Collapse>
      </Card>
  </Accordion>
        </ul>

        { /* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Saved reports</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span data-feather="plus-circle"></span>
          </a>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Current month
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Last quarter
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Social engagement
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span data-feather="file-text"></span>
              Year-end sale
            </a>
          </li>
        </ul> */ }
      </div>
    </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Nav);