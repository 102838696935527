import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Header from '../Header'
import Nav from '../Nav'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'

class Roles extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            matrices: [],
            loadingUsuarios: true,
            filtroroles: '',
            rol: {},
            showcreacion: false,
            textguardado: '',
            loadingroles: true,
            roles: [],
            loadingmatrices: true 
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRol = this.handleChangeRol.bind(this)
    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeRol(e){
        const { name, value } = e.target
        const { rol } = this.state
        rol[name] = value
        console.log(rol)
        return this.setState({ rol: rol })
    }

    componentDidMount(){
        const { user } = this.state
        this.getRoles(user.tipo)
        this.getMatrices(user.propietario)
    }

    getMatrices(propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMatrices?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getRoles(tipo){

        this.setState({ loadingroles: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getRolesUsuario?tipo=${tipo}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingroles: false, roles: res })
        })
        .catch(error => {
            this.setState({ loadingroles: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }

    getMatrices(propietario){

        this.setState({ loadingmatrices: true })
        return fetch(`https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getMatrices?propietario=${propietario}`)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingmatrices: false, matrices: res })
        })
        .catch(error => {
            this.setState({ loadingmatrices: false })
            alert("Error al consultar la información, intente nuevamente")
        })

    }
    
    guardarCrear(){
        const { rol, user } = this.state

        if(!rol.nombre||!rol.idmatriz){
            return alert("Todos los campos son requeridos")
        }

        if(!rol._id){
            return alert("No está permitido crear nuevos roles de usuario, contacte al administrador del sitio")
        }

        this.setState({ guardandocambios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_crearEditarRol',{
            method: 'POST',
            body: JSON.stringify(rol),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            const texto = rol._id ? 'Rol modificado exitosamente' : 'Rol creado exitosamente'
            this.setState({ guardandocambios: false, textguardado: texto })
            this.getRoles(user.tipo)
        })
        .catch(error => {
            this.setState({ guardandocambios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }

    fetchUsuarios(condicion){
        this.setState({ loadingUsuarios: true })
        return fetch('https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/sistemahabilidades-sevzv/service/sistemahabilidadesweb/incoming_webhook/web_getUsersCustom',{
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            this.setState({ loadingUsuarios: false, usuarios: res })
        })
        .catch(error => {
            this.setState({ loadingUsuarios: false })
            alert("Error al consultar la información, intente nuevamente")
        })
    }
    getUsuariosByTipo(user){

        switch (user.tipo) {
            case 'admin':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario })
                break;
            case 'evaluator':
                this.getRoles(user.tipo)
                return this.fetchUsuarios({ propietario: user.propietario, tipo: 'empleado' })
                break;
            default:
                this.setState({ loadingUsuarios: false })
                break;
        }

    }

    statusUser(tipo){
        switch (tipo) {
            case '1':
                    return 'Activo'
                    break;
            default:
                return 'Inactivo'
                break;
        }
    }

    tipoUser(tipo){
        switch (tipo) {
            case 'admin':
                    return 'Administrador'
                    break;
            case 'evaluator':
                    return 'Evaluador'
                    break;
            case 'empleado':
                    return 'Usuario'
                    break;        
            default:
                return tipo
                break;
        }
    }

    formularioNuevoUser(){
        const { showcreacion, rol, guardandocambios, matrices } = this.state

        if(guardandocambios){
            return <div>
            <Spinner animation="border" />
            <h3>Guardando información...</h3>
        </div>
        }
        if(showcreacion){

            return <Card className="card-blue">
                
                <Card.Body className="text-white ">
                <Row>
                <Col md={12} >
                { rol._id ? <h3>Editar rol de {rol.nombre ? rol.nombre : 'usuario'} </h3> : <h3>Crear rol</h3> }
                </Col>

<Col md={3} >
    <label className="form-control-label">Nombre</label>
    <input name="nombre" className="form-control" value={rol.nombre ? rol.nombre : ''} onChange={this.handleChangeRol} />
</Col>


<Col md={3} >
    <label className="form-control-label">Permisos</label>
    <select name="idmatriz" className="form-control" value={rol.idmatriz ? rol.idmatriz : ''} onChange={this.handleChangeRol} >
        <option value="">Seleccione</option>
        {
            matrices.length > 0 ? matrices.map(matriz => {
                return <option value={matriz._id['$oid']}> {matriz.nombre} </option>
            }) : false
        }
    </select>
</Col>

<Col md={3}>
<label className="form-control-label">Click para { rol._id ? 'editar' : 'crear' } </label>
    <Button style={{ background: 'white' }} onClick={()=>this.guardarCrear()} variant="outline-white">{ rol._id ? 'GUARDAR CAMBIOS' : 'CREAR' }</Button>
</Col>

<Col xs={12}>
<p className="m-0" onClick={()=>this.setState({ rol: {} })}>Reiniciar formulario</p>

</Col>

</Row>
                </Card.Body>
            </Card>
        }


    }

    mostrarRoles(){
        const { loadingroles, filtroroles, textguardado, roles, showcreacion } = this.state

        if(loadingroles){
            return <div>
                <Spinner animation="border" />
                <h3>Cargando información</h3>
            </div>
        }

        const lowercasedFilter = filtroroles.toLowerCase();
        const rolesFiltrados = roles.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });
        
        return <Card style={{ width: '100%' }}>
        <Card.Body>
          <Card.Title style={{ float: 'left' }}>{roles.length} roles encontrados</Card.Title>
          <Button style={{ float: 'right', marginBottom: 15 }} onClick={()=> this.setState({ showcreacion: showcreacion ? false : true, textguardado: '' })}  variant="outline-primary">CREAR</Button>
          <div style={{ clear: 'both' }}><hr /></div>
        { textguardado ? <h2> {textguardado} </h2> : false }
        {this.formularioNuevoUser()}
          <Row>

            <Col md={3}>
            <label className="form-control-label">Filtrar resultados</label>
            <input name="filtroroles" className="form-control mb-4" placeholder="Escribe cualquier palabra clave" onChange={this.handleChange} />
            </Col>

            {filtroroles !== '' ? <Col xs={12}><label className="form-control-label">{rolesFiltrados.length} Coindicencias</label></Col>:false}
          
          </Row>          

          <Table responsive hover>
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Estado</th>
      <th>Permisos</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    { rolesFiltrados.map((rol,i)=>{
        console.log(rol)
        return <tr key={`user${i}`}>
            <th>{rol.nombre}<br/><small className="text-primary onclick" onClick={()=>this.setState({ showcreacion: true, rol: rol, textguardado: '' })}>Editar</small></th>
            <th>{this.statusUser(rol.status)}</th>
            <th>{rol.matrizpermiso ? rol.matrizpermiso.nombre : 'Sin matriz de permisos seleccionada'}</th>
            <th></th>
        </tr>
    }) }
  </tbody>
</Table>

        </Card.Body>
      </Card>
    }
    
    render(){
        return(
            <div className="fluid">
       <Header/>   
<div className="container-fluid">
  <div className="row">
   <Nav/>
    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Roles de usuario</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

      {this.mostrarRoles()}
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Roles);